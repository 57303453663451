import cn from 'classnames';
import { motion } from 'motion/react';
import transitions from '@utils/transitions';

import styles from '@styles/Forms.module.css';

export const FormHelperText = ({ children, className, ...props }) => (
  <motion.div
    className={cn(styles.helperText, className)}
    {...transitions.collapsible}
    {...props}
  >
    {children}
  </motion.div>
);
