import { ReactNode } from 'react';
import { Change } from './Change';
import css from './styles.module.css';
import { cn } from '@vault/utilities';
import { MetricSkeleton } from '@vault/Metric/Skeleton';

function formatValue(value: number | string) {
  if (typeof value === 'number') {
    return value.toLocaleString();
  } else {
    return value;
  }
}

export interface MetricProps {
  className?: string;
  value: number | string;
  max?: number | string;
  suffix?: ReactNode;
  variant?: 'primary' | 'danger';
}

function Metric({ value, max, suffix, className, variant }: MetricProps) {
  return (
    <div className={cn(css.row, className)}>
      <p className={css.metric}>
        <span className={css.value} data-variant={variant}>
          {formatValue(value)}
        </span>
        {max && (
          <>
            <span className={css.divider} aria-hidden>
              /
            </span>
            <span className={css.max}>{formatValue(max)}</span>
          </>
        )}
      </p>

      {suffix}
    </div>
  );
}

Metric.Change = Change;
Metric.Skeleton = MetricSkeleton;

export { Metric };
