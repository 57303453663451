import { Navigate, useNavigate, useParams } from 'react-router';
import css from './styles.module.css';
import { Card } from '@common/Card/Card';
import CreateApp from '@components/TeamApp/CreateApp/CreateApp';
import { useCurrentTeam } from '@queries/user';

export function TeamRoot() {
  const navigate = useNavigate();
  const { teamUuid } = useParams();
  const { team } = useCurrentTeam();
  const apps = team?.apps || [];

  if (apps.length > 0) {
    const app = apps[0];
    return <Navigate to={app.uuid} replace />;
  }

  const openApp = (app) => {
    navigate(app.uuid);
  };

  return (
    <div className={css.container}>
      <Card className={css.card}>
        <h2 className={css.title}>Create your first app</h2>
        <p>
          Apps allow you to separate your development, staging, and production
          environments with each App using its own keys.
        </p>
        <CreateApp teamUuid={teamUuid} onCreate={openApp} />
      </Card>
    </div>
  );
}
