import React, { PropsWithChildren } from 'react';
import styles from './MixedList.module.css';
import cn from 'classnames';

export interface MixedListProps extends PropsWithChildren {
  className?: string;
}

export default function MixedList({ children, className }: MixedListProps) {
  return <div className={cn(styles.container, className)}>{children}</div>;
}

interface RowProps extends PropsWithChildren {
  showActionOnHover?: boolean;
  isHeader?: boolean;
  isWarning?: boolean;
  onClick?: () => void;
}

const Row = ({
  children,
  showActionOnHover = false,
  isHeader = false,
  isWarning = false,
  onClick,
}: RowProps) => (
  <div
    onClick={onClick}
    data-header={isHeader}
    className={cn(
      styles.row,
      showActionOnHover && styles.showActionOnHover,
      isWarning && styles.rowWarning,
      onClick && styles.rowClickable
    )}
  >
    {children}
  </div>
);

const Data = ({ children }: PropsWithChildren) => (
  <div className="flex-fill">{children}</div>
);

const Action = ({ children }: PropsWithChildren) => (
  <div className={styles.action}>{children}</div>
);

MixedList.Row = Row;
MixedList.Data = Data;
MixedList.Action = Action;
