import { Fragment, PropsWithChildren, useCallback, useId } from 'react';
import css from './styles.module.css';
import { LINE_HEIGHT } from '@vault/Diff/utilities';
import { Plus } from 'lucide-react';

export interface PreviewModeProps extends PropsWithChildren {
  enabled: boolean;
  lines: number;
  active: boolean;
  onActiveChange(active: boolean): void;
}

export function PreviewMode({
  children,
  enabled,
  lines,
  active,
  onActiveChange,
}: PreviewModeProps) {
  const id = useId();

  const handleActiveChange = useCallback(() => {
    onActiveChange(!active);
  }, [active, onActiveChange]);

  return (
    <div
      className={css.previewable}
      data-can-preview={enabled}
      data-active={active}
      style={{
        '--line-height': `${LINE_HEIGHT}px`,
        '--preview-lines': lines,
      }}
    >
      {enabled && (
        <Fragment>
          <div className={css.previewGradient} />

          <button
            className={css.previewButton}
            aria-expanded={!active}
            aria-controls={id}
            onClick={handleActiveChange}
          >
            <Plus className={css.previewButtonIcon} aria-hidden="true" />
            {active ? 'Expand' : 'Collapse'}
          </button>
        </Fragment>
      )}

      <div id={id} className={css.previewContent}>
        {children}
      </div>
    </div>
  );
}
