import css from './styles.module.css';
import { ComponentPropsWithoutRef } from 'react';

type PaginationButtonProps = ComponentPropsWithoutRef<'button'> & {
  isActive?: boolean;
};

export function PaginationButton({
  children,
  isActive,
  ...props
}: PaginationButtonProps) {
  return (
    <li>
      <button className={css.button} aria-current={isActive} {...props}>
        <span className={css.buttonLabel}>{children}</span>
        <span aria-hidden className={css.buttonBackground} />
      </button>
    </li>
  );
}
