import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'motion/react';
import css from './Drawer.module.css';
import DrawerClose from './DrawerClose';
import DrawerContent from './DrawerContent';
import DrawerFooter from './DrawerFooter';
import DrawerHeader from './DrawerHeader';
import DrawerTitle from './DrawerTitle';
import { DrawerState } from './useDrawer';
import { createContext, useContext } from 'react';
export { default as useDrawer } from './useDrawer';
export type { DrawerState } from './useDrawer';

type DrawerProps = {
  state: DrawerState;
  children: React.ReactNode;
  autoFocus?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

const DrawerContext = createContext<DrawerState | null>(null);
export const useDrawerContext = () => useContext(DrawerContext);

function Drawer({ state, children, autoFocus, onOpenChange }: DrawerProps) {
  const handleAutoFocus = (e) => {
    if (autoFocus) return;
    e.preventDefault();
  };

  const handleOpenChange = (isOpen: boolean) => {
    state.onOpenChange?.(isOpen);
    state.setIsOpen(isOpen);
    onOpenChange?.(isOpen);
  };

  return (
    <DrawerContext.Provider value={state}>
      <Dialog.Root open={state.isOpen} onOpenChange={handleOpenChange}>
        <AnimatePresence>
          {state.isOpen && (
            <Dialog.Portal forceMount>
              <Dialog.Overlay asChild>
                <motion.div className={css.overlay} {...OVERLAY_ANIMATIONS} />
              </Dialog.Overlay>
              <Dialog.Content onOpenAutoFocus={handleAutoFocus} asChild>
                <motion.div className={css.drawer} {...DRAWER_ANIMATIONS}>
                  {children}
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </DrawerContext.Provider>
  );
}

const OVERLAY_ANIMATIONS = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
  },
};

const DRAWER_ANIMATIONS = {
  initial: {
    x: 200,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 200,
    opacity: 0,
  },
  transition: {
    duration: 0.2,
  },
};

const DrawerNamespace = Object.assign(Drawer, {
  Content: DrawerContent,
  Header: DrawerHeader,
  Title: DrawerTitle,
  Close: DrawerClose,
  Footer: DrawerFooter,
});

export { DrawerNamespace as Drawer };
