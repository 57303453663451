import { ComponentProps, ComponentPropsWithoutRef } from 'react';

export function FooterLogo(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.8321 5.40971C20.4235 5.60345 20.8268 6.15122 20.836 6.77344V9.6939C20.6197 9.388 20.3075 9.15042 19.9341 9.02812L12.1797 6.48804C11.0202 6.10821 9.75523 6.22439 8.68427 6.80907L4.58447 9.04734C4.42056 9.13683 4.27854 9.2556 4.16357 9.39527V6.35309C4.17301 5.95662 4.39325 5.59523 4.74143 5.40515L7.34939 3.98135C9.29296 2.92027 11.5886 2.70943 13.693 3.39874L19.8321 5.40971Z"
        stroke="currentColor"
        strokeWidth="0.327553"
      />
      <path
        d="M4 9.91601L4 5.98633C4.0098 6.67894 4.45843 7.28888 5.1167 7.5045L11.2559 9.51545C13.4031 10.2188 15.7456 10.0037 17.7289 8.92097L20.3368 7.49717C20.737 7.27872 20.9899 6.86312 21 6.40736V10.3371C20.9899 10.7928 20.737 11.2084 20.3368 11.4269L17.7289 12.8506C15.7456 13.9334 13.4031 14.1485 11.2559 13.4451L5.1167 11.4342C4.45843 11.2186 4.0098 10.6086 4 9.91601Z"
        fill="currentColor"
      />
      <path
        d="M19.8321 12.4522C20.4235 12.6459 20.8268 13.1937 20.836 13.8159V16.7364C20.6197 16.4305 20.3075 16.1929 19.9341 16.0706L12.1797 13.5305C11.0202 13.1507 9.75523 13.2669 8.68427 13.8516L4.58447 16.0898C4.42056 16.1793 4.27854 16.2981 4.16357 16.4378V13.3956C4.17301 12.9991 4.39325 12.6377 4.74143 12.4476L7.34939 11.0238C9.29296 9.96275 11.5886 9.75191 13.693 10.4412L19.8321 12.4522Z"
        stroke="currentColor"
        strokeWidth="0.327553"
      />
      <path
        d="M4 16.9584L4 13.0287C4.0098 13.7213 4.45843 14.3312 5.1167 14.5469L11.2559 16.5578C13.4031 17.2612 15.7456 17.0461 17.7289 15.9633L20.3368 14.5395C20.737 14.3211 20.9899 13.9055 21 13.4497V17.3794C20.9899 17.8352 20.737 18.2508 20.3368 18.4692L17.7289 19.893C15.7456 20.9757 13.4031 21.1909 11.2559 20.4875L5.1167 18.4765C4.45843 18.2609 4.0098 17.651 4 16.9584Z"
        fill="currentColor"
      />
    </svg>
  );
}
