import * as Primitive from '@radix-ui/react-dialog';
import { DialogState } from './useDialog';
import { motion, AnimatePresence } from 'motion/react';
import css from './Dialog.module.css';
import { DialogModal } from './DialogModal';
import { createContext, useContext } from 'react';
import { DialogConfirmation } from './DialogConfirmation';
import { DialogSection } from './DialogSection';
import { Button } from '@vault/Button';
import { X } from 'lucide-react';
export { default as useDialog } from './useDialog';
export type { DialogState } from './useDialog';

type DialogProps = {
  state: DialogState;
  children: React.ReactNode;
  trigger?: React.ReactNode;
  className?: string;
  closeButton?: boolean;
};

const DialogContext = createContext<DialogState | null>(null);
export const useDialogContext = () => {
  const ctx = useContext(DialogContext);

  if (!ctx) {
    throw new Error('useDialogContext must be used within a Dialog component');
  }

  return ctx;
};

function Dialog({
  state,
  children,
  className,
  trigger,
  closeButton,
}: DialogProps) {
  const classes = [css.window, className].filter(Boolean).join(' ');

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <DialogContext.Provider value={state}>
      <Primitive.Root open={state.isOpen} onOpenChange={state.setIsOpen}>
        {trigger && <Primitive.Trigger asChild>{trigger}</Primitive.Trigger>}
        <AnimatePresence>
          {state.isOpen && (
            <Primitive.Portal forceMount>
              <Primitive.Overlay asChild>
                <motion.div
                  className={css.overlay}
                  {...OVERLAY_ANIMATIONS}
                  onClick={handleClick}
                >
                  <Primitive.Content asChild>
                    <motion.div className={classes} {...WINDOW_ANIMATIONS}>
                      {children}

                      {closeButton && (
                        <Primitive.Close asChild>
                          <Button size="xs" className={css.close}>
                            <X />
                          </Button>
                        </Primitive.Close>
                      )}
                    </motion.div>
                  </Primitive.Content>
                </motion.div>
              </Primitive.Overlay>
            </Primitive.Portal>
          )}
        </AnimatePresence>
      </Primitive.Root>
    </DialogContext.Provider>
  );
}

const OVERLAY_ANIMATIONS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2 },
};

const WINDOW_ANIMATIONS = {
  initial: { y: 12 },
  animate: { y: 0 },
  exit: { y: 12 },
};

const DialogNamespace = Object.assign(Dialog, {
  Modal: DialogModal,
  Confirmation: DialogConfirmation,
  Section: DialogSection,
});

export { DialogNamespace as Dialog };
