import { Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router';
import ModalProvider from '@contexts/ModalContext';

import SentryRoutes from './SentryRoutes';
import { LaunchDarklyProvider } from './LaunchDarklyProvider';
import { lazyRoute } from '@utils/lazyRoute';
import { TeamRoot } from './TeamRoot';
import TeamApp from '@routes/TeamApp/TeamApp';
import { RedirectToFirstTeam } from './RedirectToFirstTeam';
import { RequireAuthOutlet, RequireNotAuthedOutlet } from './Gateways';
import Layout from '@components/Layout';
import * as Layouts from './Layouts';

const Providers = ({ children }) => (
  <ModalProvider>
    <LaunchDarklyProvider>{children}</LaunchDarklyProvider>
  </ModalProvider>
);

const Admin = lazyRoute(() => import('@components/Admin'));
const Vercel = lazyRoute(() => import('@components/VercelIntegration'));
const TeamSettings = lazyRoute(() => import('./TeamSettings'));

const AuthGitHub = lazyRoute(() => import('@components/AuthGithub'));
const OAuthCallback = lazyRoute(
  () => import('@components/Auth/OAuthCallback/OAuthCallback')
);
const BillingSuccess = lazyRoute(
  () => import('@components/Auth/BillingSuccess/BillingSuccess')
);

const NotificationCallback = lazyRoute(
  () => import('@components/Auth/NotificationCallback/NotificationCallback')
);

const Invites = lazyRoute(() => import('@components/Invites/Invites'));
const AccountSettings = lazyRoute(() => import('@components/AccountSettings'));
const Login = lazyRoute(() => import('@components/Auth/Login/Login'));
const Register = lazyRoute(() => import('@components/Auth/Register/Register'));
const VerifyEmail = lazyRoute(
  () => import('@components/Auth/VerifyEmail/VerifyEmail')
);
const Callback = lazyRoute(() => import('@components/Auth/Callback/Callback'));

const VaultDocs = lazyRoute(() => import('@vault/Documentation'));

function RootRouter() {
  return (
    <Suspense fallback={<Layout.Loader />}>
      <BrowserRouter>
        <Providers>
          <SentryRoutes>
            <Route path="vault/*" element={<VaultDocs />} />

            <Route element={<Layouts.AdminLayout />}>
              <Route path="admin/*" element={<Admin />} />
            </Route>

            <Route element={<RequireNotAuthedOutlet />}>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
            </Route>

            <Route path="callback" element={<Callback />} />
            <Route path="verify" element={<VerifyEmail />} />
            <Route path="invites" element={<Invites />} />
            <Route path="oauth">
              <Route path="github" element={<AuthGitHub />} />
              <Route path="callback" element={<OAuthCallback />} />
            </Route>

            <Route path="webhooks">
              <Route
                path="slack"
                element={<NotificationCallback type="SLACK" />}
              />
            </Route>

            <Route path="notifications">
              <Route
                path="slack"
                element={<NotificationCallback type="SLACK" />}
              />
              <Route
                path="discord"
                element={<NotificationCallback type="DISCORD" />}
              />
            </Route>

            <Route element={<RequireAuthOutlet />}>
              <Route element={<Layouts.TeamSettingsLayout />}>
                <Route path=":teamUuid/settings/*" element={<TeamSettings />} />
              </Route>

              <Route element={<Layouts.AccountSettings />}>
                <Route path="settings" element={<AccountSettings />} />
              </Route>

              <Route element={<Layouts.AppLayout />}>
                <Route index element={<RedirectToFirstTeam />} />
                <Route element={<Layouts.TeamLayout />}>
                  <Route path=":teamUuid" element={<TeamRoot />} />
                  <Route path=":teamUuid/:appUuid/*" element={<TeamApp />} />
                </Route>
              </Route>

              <Route path="integrations/vercel/callback" element={<Vercel />} />
              <Route path="billing/success" element={<BillingSuccess />} />
            </Route>
          </SentryRoutes>
        </Providers>
      </BrowserRouter>
    </Suspense>
  );
}

export default RootRouter;
