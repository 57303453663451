import { useMemo } from 'react';
import css from './styles.module.css';
import { ArrowDownLeft, ArrowUpRight, Minus } from 'lucide-react';
import { Tooltip } from '@vault/Tooltip';

type ChangeStatus = 'neutral' | 'positive' | 'negative';

export interface ChangeProps {
  from: number;
  to: number;
  tooltip?: string | ((value: string, status: ChangeStatus) => string);
}

export function Change({ from, to, tooltip }: ChangeProps) {
  const status = from === to ? 'neutral' : to > from ? 'positive' : 'negative';

  const difference = Math.abs(to - from);
  const change = (difference / from) * 100;

  const value = useMemo(() => {
    if (change === 0) return '0%';

    const decimals = change < 10 ? 1 : 0;
    return `${change.toFixed(decimals)}%`;
  }, [change]);

  const label = useMemo(() => {
    if (status === 'neutral') {
      return 'No change';
    } else if (status === 'positive') {
      return `Increased by ${value}%`;
    } else {
      return `Decreased by ${value}%`;
    }
  }, [status, value]);

  const content = (
    <p className={css.change} data-status={status} aria-label={label}>
      <span className={css.changeValue}>{value}</span>
      <span className={css.changeIcon} aria-hidden>
        {status === 'neutral' ? (
          <Minus />
        ) : status === 'positive' ? (
          <ArrowUpRight />
        ) : (
          <ArrowDownLeft />
        )}
      </span>
    </p>
  );

  if (!tooltip) {
    return content;
  }

  const tooltipContent =
    typeof tooltip === 'string' ? tooltip : tooltip(value, status);

  return (
    <Tooltip align="start" side="top" content={tooltipContent}>
      {content}
    </Tooltip>
  );
}
