export interface HoverHighlightProps {
  width: number;
  height: number;
  x: number;
  y: number;
  radius: number;
}

export function HoverHighlight({
  width,
  height,
  x,
  y,
  radius,
}: HoverHighlightProps) {
  return (
    <>
      <rect
        width={width}
        height={height}
        fill="#F5F3FA"
        rx={radius}
        x={x}
        y={y}
      />

      {/* Render a square rect to "remove" rounded bottom corners */}
      {radius > 0 && (
        <rect
          width={width}
          height={radius}
          fill="#F5F3FA"
          x={x}
          y={y + (height - radius)}
        />
      )}
    </>
  );
}
