import css from './styles.module.css';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  forwardRef,
  useMemo,
} from 'react';
import { Link as RouterLink } from 'react-router';

type BaseLinkProps = Omit<ComponentProps<'a'>, 'href'>;

type LinkProps = BaseLinkProps & {
  href: string;
  external?: boolean;
  variant?: 'primary' | 'secondary';
};

const validProtocols = ['http', 'https', 'mailto', 'tel'];

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  { href, children, variant, external, ...props },
  ref
) {
  const isExternal = useMemo(
    () =>
      external ?? validProtocols.some((protocol) => href.startsWith(protocol)),
    [external, href]
  );

  if (isExternal) {
    return (
      <a
        ref={ref}
        className={css.link}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-variant={variant}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      ref={ref}
      className={css.link}
      to={href}
      data-variant={variant}
      {...props}
    >
      {children}
    </RouterLink>
  );
});
