import { usePaginationContext } from '.';
import { Ellipsis } from './Ellipsis';
import { PageButton } from './PageButton';

export function Pages() {
  const pages = [];
  const { page, totalPages } = usePaginationContext();
  const pagesToShow = 7;
  const halfPagesToShow = Math.floor(pagesToShow / 2);

  // Less pages than pagesToShow
  if (totalPages <= pagesToShow) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(<PageButton key={i} page={i} />);
    }

    return pages;
  }

  // First few pages
  if (page <= halfPagesToShow + 1) {
    for (let i = 1; i <= pagesToShow; i++) {
      pages.push(<PageButton key={i} page={i} />);
    }

    pages.push(<Ellipsis key="ellipsis-end" />);
    pages.push(<PageButton key={totalPages} page={totalPages} />);

    return pages;
  }

  // Last few pages
  if (page >= totalPages - halfPagesToShow) {
    pages.push(<PageButton key={1} page={1} />);
    pages.push(<Ellipsis key="ellipsis-start" />);

    for (let i = totalPages - pagesToShow + 2; i <= totalPages; i++) {
      pages.push(<PageButton key={i} page={i} />);
    }

    return pages;
  }

  // Middle pages
  pages.push(<PageButton key={1} page={1} />);
  pages.push(<Ellipsis key="ellipsis-start" />);
  for (
    let i = page - halfPagesToShow + 1;
    i <= page + halfPagesToShow - 1;
    i++
  ) {
    pages.push(<PageButton key={i} page={i} />);
  }
  pages.push(<Ellipsis key="ellipsis-end" />);
  pages.push(<PageButton key={totalPages} page={totalPages} />);

  return pages;
}
