import css from './Dialog.module.css';
import { Dialog, DialogState, Button } from '@vault';
import * as Primitive from '@radix-ui/react-dialog';
import { useState } from 'react';

type DialogConfirmationProps = {
  state: DialogState;
  title: string;
  label?: string;
  trigger?: React.ReactNode;
  children: React.ReactNode;
  onConfirm: () => Promise<void>;
};

export function DialogConfirmation({
  state,
  title,
  children,
  label,
  trigger,
  onConfirm,
}: DialogConfirmationProps) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    state.close();
  };

  return (
    <Dialog trigger={trigger} state={state} className={css.confirmation}>
      <div className={css.body}>
        <Primitive.Title asChild>
          <h3 className="mb-2">{title || 'Are you sure?'}</h3>
        </Primitive.Title>
        {children}
      </div>
      <div className={css.footer}>
        <Button variant="primary" loading={loading} onClick={handleConfirm}>
          {label || 'Confirm'}
        </Button>
        <Button disabled={loading} onClick={state.close}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
