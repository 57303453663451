import { DiffChunk, LINE_HEIGHT } from '@vault/Diff/utilities';
import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import css from './styles.module.css';
import {
  Chunk,
  chunkKey,
  ChunkPosition,
  CollapsibleChunk,
  getDisplayedLines,
} from '@vault/Diff/Chunk';
import { useVirtualizer } from '@tanstack/react-virtual';
import { PreviewMode } from '@vault/Diff/PreviewMode';
import DiffWorker from './worker?worker';

export interface DiffProps {
  /** The original code */
  original: string;
  /** The updated code */
  updated: string;
  /** The maximum number of lines to display */
  lines?: number;
  /** The number of lines to display in preview mode. Default is 0 (disabled) */
  previewLines?: number;
  /** The fallback to display when the diff is loading */
  fallback?: ReactNode;
  /** The callback to call when an error occurs */
  onError?(error: Error): void;
}

export interface Diff {
  expandAll(): void;
  collapseAll(): void;
  scrollToTop(): void;
}

/**
 * Highlight if:
 * - The chunk has a single line
 * - The next chunk is not common (so, added or removed)
 * - The next chunk also has a single line
 */
function shouldHighlight(a: DiffChunk, b: DiffChunk | undefined) {
  return (
    a.lines.length === 1 &&
    b &&
    b.lines[0].type !== 'common' &&
    b.lines.length === 1
  );
}

function getPosition(index: number, maxIndex: number): ChunkPosition {
  if (index === 0) return 'start';
  if (index === maxIndex) return 'end';
  return 'middle';
}

function useKeyedRef<T extends { key: string }>() {
  const ref = useRef<Record<string, T>>({});

  const assign = useCallback((value: T) => {
    if (!value) return;
    ref.current[value.key] = value;
  }, []);

  return useMemo(() => [ref, assign] as const, [assign]);
}

interface DiffInnerProps {
  chunks: DiffChunk[];
  lines?: number;
  previewLines?: number;
}

const DiffInner = forwardRef<Diff, DiffInnerProps>(function DiffInner(
  { chunks, lines = 15, previewLines = 0 },
  ref
) {
  const [chunkRefs, assignChunkRef] = useKeyedRef<Chunk>();

  const expandedChunksRef = useRef<Set<string>>(new Set());

  const parentRef = useRef<HTMLPreElement>(null);
  const getScrollElement = useCallback(() => parentRef.current, []);
  const estimateSize = useCallback(
    (index: number) => {
      const chunk = chunks[index];
      const chunkRef = chunkRefs.current[chunkKey(chunk)];
      let lineCount: number;
      if (chunkRef) {
        lineCount = chunkRef.getLineCount();
      } else if (chunk.type === 'common') {
        const position = getPosition(index, chunks.length - 1);
        lineCount = getDisplayedLines(chunk, position).length;
      } else {
        lineCount = chunk.lines.length;
      }
      const height = lineCount * LINE_HEIGHT;
      return height;
    },
    [chunks]
  );
  const rowVirtualizer = useVirtualizer({
    count: chunks.length,
    getScrollElement,
    estimateSize,
    overscan: 50,
  });
  const rows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const canPreview = !!previewLines && totalSize > LINE_HEIGHT * previewLines;
  const [previewActive, setPreviewActive] = useState(canPreview);

  const onExpand = useCallback((chunk: DiffChunk) => {
    expandedChunksRef.current.add(chunkKey(chunk));
    requestAnimationFrame(() => rowVirtualizer.measure());
  }, []);

  const expandAll = useCallback(() => {
    if (previewActive) return;
    // Expand all chunks
    Object.values(chunkRefs.current).forEach((chunk) => {
      chunk?.expand();
      // Add the chunk to the expanded chunks set
      if (chunk) expandedChunksRef.current.add(chunk.key);
    });
    // Re-measure the virtualizer to account for expanded lines
    requestAnimationFrame(() => rowVirtualizer.measure());
  }, [previewActive]);

  const collapseAll = useCallback(() => {
    // Collapse all chunks
    Object.values(chunkRefs.current).forEach((chunk) => {
      chunk?.collapse();
    });
    // Clear the expanded chunks
    expandedChunksRef.current.clear();
    // Re-measure the virtualizer to account for collapsed lines
    requestAnimationFrame(() => rowVirtualizer.measure());
  }, [previewActive]);

  const scrollToTop = useCallback(() => {
    parentRef.current?.scrollTo({ top: 0, behavior: 'auto' });
  }, []);

  const handlePreviewActiveChange = useCallback(
    (active: boolean) => {
      setPreviewActive(active);
      if (active) {
        collapseAll();
        scrollToTop();
      }
    },
    [collapseAll, scrollToTop]
  );

  useImperativeHandle(
    ref,
    useCallback(
      () => ({ expandAll, collapseAll, scrollToTop }),
      [expandAll, collapseAll, scrollToTop]
    )
  );

  // When chunks are updated, re-measure the virtualizer to account for collapsed lines
  // note: we need `useEffect` here so it's called after first render
  useEffect(() => {
    requestAnimationFrame(() => rowVirtualizer.measure());
  }, [chunks]);

  // When chunks are updated...
  useLayoutEffect(() => {
    const parent = parentRef.current;
    if (!parent) return;

    // Scroll to top of parent element
    parent.scrollTo({ top: 0, behavior: 'auto' });
    // Remove line number column widths
    parent.style.removeProperty(`--original-line-number-width`);
    parent.style.removeProperty(`--updated-line-number-width`);
    // Re-measure the virtualizer (once all chunks have been rendered)
  }, [chunks]);

  // When the virtualizer is updated, re-measure line number column widths
  useLayoutEffect(() => {
    const parent = parentRef.current;

    function updateLineNumberWidth(id: string) {
      const lineNumbers: HTMLElement[] = Array.from(
        parent?.querySelectorAll(`[data-id="line-number-${id}"]`) ?? []
      );
      const lineNumberWidth = Math.max(
        0,
        ...Array.from(lineNumbers).map((el) => el.offsetWidth)
      );
      if (lineNumberWidth) {
        parent?.style.setProperty(
          `--${id}-line-number-width`,
          `${lineNumberWidth}px`
        );
      }
    }

    updateLineNumberWidth('original');
    updateLineNumberWidth('updated');
  }, [rows]);

  return (
    <PreviewMode
      enabled={canPreview}
      lines={previewLines}
      active={previewActive}
      onActiveChange={handlePreviewActiveChange}
    >
      <pre
        ref={parentRef}
        className={css.pre}
        style={{ '--max-visible-lines': lines }}
      >
        <code className={css.code} style={{ '--height': `${totalSize}px` }}>
          <span
            className={css.chunks}
            style={{ '--translate-y': `${rows.at(0)?.start ?? 0}px` }}
          >
            {rows.map((virtualChunk) => {
              const index = virtualChunk.index;
              const chunk = chunks[index];

              const position = getPosition(index, chunks.length - 1);

              const prevChunk = index === 0 ? undefined : chunks.at(index - 1);
              const nextChunk = chunks.at(index + 1);
              const isHighlighted =
                shouldHighlight(chunk, prevChunk) ||
                shouldHighlight(chunk, nextChunk);
              const isExpanded = expandedChunksRef.current.has(chunkKey(chunk));

              return (
                <span key={virtualChunk.key} className={css.chunk}>
                  {chunk.type === 'common' ? (
                    <CollapsibleChunk
                      ref={assignChunkRef}
                      chunk={chunk}
                      position={position}
                      disabled={previewActive}
                      initialExpanded={isExpanded}
                      onExpand={onExpand}
                    />
                  ) : (
                    <Chunk chunk={chunk} highlight={isHighlighted} />
                  )}
                </span>
              );
            })}
          </span>
        </code>
      </pre>
    </PreviewMode>
  );
});

export const Diff = forwardRef<Diff, DiffProps>(function Diff(
  { original, updated, lines, previewLines, onError, fallback },
  ref
) {
  const [chunks, setChunks] = useState<DiffChunk[] | null>(null);

  // Use a ref to avoid re-starting effect when `onError` changes
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  useEffect(() => {
    setChunks(null);

    function handleMessage(event: MessageEvent) {
      setChunks(event.data);
    }

    function handleError(event: ErrorEvent) {
      onErrorRef.current?.(event.error);
    }

    const worker = new DiffWorker();
    worker.addEventListener('message', handleMessage);
    worker.addEventListener('error', handleError);
    worker.postMessage({ original, updated });

    return () => {
      worker.removeEventListener('message', handleMessage);
      worker.removeEventListener('error', handleError);
      worker.terminate();
    };
  }, [original, updated]);

  if (!chunks?.length) {
    return fallback ?? null;
  }

  return (
    <DiffInner
      ref={ref}
      chunks={chunks}
      lines={lines}
      previewLines={previewLines}
    />
  );
});
