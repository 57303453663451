import { cn } from '@vault/utilities';
import styles from './styles.module.css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { motion } from 'motion/react';

type MenuItemProps = DropdownMenu.DropdownMenuItemProps & {
  variant?: 'danger';
  icon?: React.ReactNode;
};

export function MenuItem({
  className,
  variant,
  children,
  icon,
  ...props
}: MenuItemProps) {
  return (
    <DropdownMenu.Item asChild {...props}>
      <motion.div
        className={cn(styles.menuItem, className)}
        data-variant={variant}
        exit={{ opacity: 0.99 }}
        transition={{ duration: 0.2 }}
      >
        {icon && (
          <span className={styles.icon} aria-hidden>
            {icon}
          </span>
        )}
        <span className={styles.label}>{children}</span>
      </motion.div>
    </DropdownMenu.Item>
  );
}
