import { LucideProps } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  ComponentType,
  ReactNode,
  useState,
} from 'react';
import css from './styles.module.css';
import { cn, easeOutExpo } from '@vault/utilities';
import * as Tooltip from '@radix-ui/react-tooltip';
import { AnimatePresence, cubicBezier, motion } from 'motion/react';

function TagBorder(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="0 0 32 26"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      overflow="visible"
      aria-hidden
      {...props}
    >
      <path
        d="M13.4517 8.9743L16.5483 17.0257C18.6278 22.4324 23.8224 26 29.6152 26H32V0H0.384812C6.17762 0 11.3722 3.56761 13.4517 8.9743Z"
        fill="currentColor"
      />
    </svg>
  );
}

export interface TaggedBannerProps {
  text: string;
  icon?: ComponentType<LucideProps>;
  tooltip?: ReactNode;
}

export function TaggedBanner({ text, icon: Icon, tooltip }: TaggedBannerProps) {
  const [open, setOpen] = useState(false);

  return (
    <motion.div
      className={css.banner}
      initial={{ opacity: 0, y: -4 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{
        opacity: 0,
        y: -4,
        transition: { delay: 0.1, duration: 0.6, ease: easeOutExpo },
      }}
      transition={{ duration: 0.4, ease: easeOutExpo }}
    >
      <Tooltip.Root delayDuration={50} open={open} onOpenChange={setOpen}>
        <Tooltip.Trigger asChild>
          <motion.div
            className={css.tag}
            initial={{ y: -32 }}
            animate={{ y: 0 }}
            exit={{ y: -32, transition: { duration: 0.5, ease: easeOutExpo } }}
            transition={{ duration: 0.4, ease: easeOutExpo }}
          >
            <TagBorder className={cn(css.tagEdge, css.tagEdgeLeft)} />
            <TagBorder className={cn(css.tagEdge, css.tagEdgeRight)} />

            <TagBorder
              className={cn(css.tagEdgeBorder, css.tagEdgeBorderLeft)}
            />
            <TagBorder
              className={cn(css.tagEdgeBorder, css.tagEdgeBorderRight)}
            />

            <div className={css.tagInner}>
              <motion.div
                className={css.content}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.4, delay: 0, ease: easeOutExpo },
                }}
                transition={{
                  duration: 0.4,
                  delay: 0.1,
                  ease: easeOutExpo,
                }}
              >
                {Icon && <Icon className={css.icon} aria-hidden />}
                <p className={css.text}>{text}</p>
              </motion.div>
            </div>
          </motion.div>
        </Tooltip.Trigger>

        <AnimatePresence>
          {tooltip && open && (
            <Tooltip.Portal forceMount>
              <Tooltip.Content
                asChild
                className={css.tooltip}
                align="center"
                side="bottom"
                sideOffset={6}
                collisionPadding={10}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.98, y: -2 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.98, y: -2 }}
                  transition={{
                    duration: 0.3,
                    ease: cubicBezier(0.16, 1, 0.3, 1),
                  }}
                >
                  {tooltip}
                </motion.div>
              </Tooltip.Content>
            </Tooltip.Portal>
          )}
        </AnimatePresence>
      </Tooltip.Root>
    </motion.div>
  );
}
