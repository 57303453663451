import {
  createContext,
  ComponentPropsWithoutRef,
  useId,
  useContext,
} from 'react';
import { motion } from 'motion/react';
import css from './styles.module.css';
import * as Label from '@radix-ui/react-label';
import { AnimatePresence } from 'motion/react';

type FormFieldProps = ComponentPropsWithoutRef<'div'> & {
  label: string;
  hint?: React.ReactNode;
  error?: string;
  htmlFor?: string;
  helperText?: React.ReactNode;
};

type FormFieldContext = {
  id: string;
  error?: string;
};

const FormFieldContext = createContext<FormFieldContext | null>(null);

export function useFormField() {
  return useContext(FormFieldContext);
}

export function FormField({
  hint,
  label,
  error,
  htmlFor,
  children,
  helperText,
  ...props
}: FormFieldProps) {
  const id = useId();

  return (
    <FormFieldContext.Provider value={{ id, error }}>
      <div {...props}>
        <div className={css.labels}>
          <Label.Root className={css.label} htmlFor={htmlFor || id}>
            {label}
          </Label.Root>
          {hint ? <span className={css.hint}>{hint}</span> : null}
        </div>
        {helperText ? <p className={css.helperText}>{helperText}</p> : null}
        {children}
        <AnimatePresence initial={false}>
          {error ? (
            <motion.p {...ERROR_ANIMATION} className={css.error}>
              {error}
            </motion.p>
          ) : null}
        </AnimatePresence>
      </div>
    </FormFieldContext.Provider>
  );
}

const ERROR_ANIMATION = {
  initial: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
  animate: {
    height: 'auto',
    opacity: 1,
    overflow: 'visible',
  },
  exit: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
  },
};
