import * as RadixTabs from '@radix-ui/react-tabs';
import { motion } from 'motion/react';
import { TriggerProps } from './Trigger';
import { PropsWithChildren } from 'react';

export interface TabProps extends PropsWithChildren<TriggerProps> {
  className?: string;
}

export function Tab({ children, label, className }: TabProps) {
  return (
    <RadixTabs.Content key={label} value={label} asChild>
      <motion.div {...TAB_ANIMATIONS} className={className}>
        {children}
      </motion.div>
    </RadixTabs.Content>
  );
}

const TAB_ANIMATIONS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
};
