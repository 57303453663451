import { motion } from 'motion/react';
import css from './styles.module.css';
import { useMemo } from 'react';

export interface SteppedLineChartSkeletonProps {
  color: string;
  count?: number;
}

export function SteppedLineChartSkeleton({
  color,
  count = 24,
}: SteppedLineChartSkeletonProps) {
  const bars = useMemo(() => new Array(count).fill(null), [count]);

  return (
    <div className={css.skeleton} style={{ '--color': color }}>
      <motion.div
        className={css.bars}
        variants={{
          hidden: { opacity: 0 },
          visible: {
            opacity: 0.3,
            transition: {
              staggerChildren: 0.03,
            },
          },
        }}
        initial="hidden"
        animate="visible"
      >
        {bars.map((_, index) => (
          <motion.div
            key={index}
            className={css.bar}
            variants={{
              hidden: { height: '10%' },
              visible: {
                height: '30%',
                transition: {
                  ease: [0.37, 0, 0.63, 1], // easeInOutSine
                  duration: 0.6,
                  repeat: Infinity,
                  repeatType: 'mirror',
                },
              },
            }}
          />
        ))}
      </motion.div>
    </div>
  );
}
