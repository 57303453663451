import { DiffChunk, DiffLine } from '@vault/Diff/utilities';
import {
  forwardRef,
  Fragment,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import css from './styles.module.css';
import {
  ArrowDownFromLine,
  ArrowUpFromLine,
  UnfoldVertical,
} from 'lucide-react';
import { Line } from '@vault/Diff/Line';
import { cn } from '@vault/utilities';

export function lineKey(line: DiffLine) {
  return `${line.number}-${line.type}`;
}

export function chunkKey(chunk: DiffChunk) {
  return `${chunk.number}-${chunk.type}`;
}

export function getDisplayedLines(
  chunk: DiffChunk,
  position: ChunkPosition
): { collapsed: boolean; line: DiffLine }[] {
  if (position === 'start') {
    if (chunk.lines.length < 3) return [];

    // Display the last line, then a preview of the previous line collapsed
    const lastLines = chunk.lines.slice(-2);
    return [
      { collapsed: true, line: lastLines[0] },
      { collapsed: false, line: lastLines[1] },
    ];
  }

  if (position === 'middle') {
    if (chunk.lines.length < 4) return [];

    // Display the first line, then a preview of the next line collapsed, then the last line
    const firstLines = chunk.lines.slice(0, 2);
    const lastLine = chunk.lines.slice(-1)[0];
    return [
      { collapsed: false, line: firstLines[0] },
      { collapsed: true, line: firstLines[1] },
      { collapsed: false, line: lastLine },
    ];
  }

  if (position === 'end') {
    if (chunk.lines.length < 3) return [];

    // Display the first line, then a preview of the next line collapsed
    const firstLines = chunk.lines.slice(0, 2);
    return [
      { collapsed: false, line: firstLines[0] },
      { collapsed: true, line: firstLines[1] },
    ];
  }

  return [];
}

export interface Chunk {
  key: string;
  expand(): void;
  collapse(): void;
  getLineCount(): number;
}

export interface ChunkProps {
  chunk: DiffChunk;
  highlight?: boolean;
}

export const Chunk = forwardRef<Chunk, ChunkProps>(function Chunk(
  { chunk, highlight },
  ref
) {
  useImperativeHandle(
    ref,
    useCallback(
      () => ({
        key: chunkKey(chunk),
        expand: () => {},
        collapse: () => {},
        getLineCount: () => chunk.lines.length,
      }),
      [chunk]
    )
  );

  return (
    <Fragment>
      {chunk.lines.map((line) => (
        <Line key={lineKey(line)} line={line} highlight={highlight} />
      ))}
    </Fragment>
  );
});

export type ChunkPosition = 'start' | 'middle' | 'end';

export interface CollapsibleChunkProps {
  chunk: DiffChunk;
  position: ChunkPosition;
  initialExpanded?: boolean;
  onExpand(chunk: DiffChunk): void;
  disabled?: boolean;
}

export const CollapsibleChunk = forwardRef<Chunk, CollapsibleChunkProps>(
  function CollapsibleChunk(
    { chunk, position, initialExpanded, onExpand, disabled },
    ref
  ) {
    const [expanded, setExpanded] = useState(initialExpanded ?? false);

    const displayed = useMemo(
      () => getDisplayedLines(chunk, position),
      [chunk, position]
    );

    const expand = useCallback(() => {
      setExpanded(true);
      onExpand(chunk);
    }, [chunk, onExpand]);

    useImperativeHandle(
      ref,
      useCallback(
        () => ({
          key: chunkKey(chunk),
          expand,
          collapse: () => setExpanded(false),
          getLineCount: () => {
            if (expanded || !displayed.length) {
              return chunk.lines.length;
            } else {
              return displayed.length;
            }
          },
        }),
        [chunk, displayed.length, expanded, expand]
      )
    );

    if (expanded || !displayed.length) {
      return <Chunk chunk={chunk} />;
    }

    let Icon = UnfoldVertical;
    if (position === 'start') {
      Icon = ArrowUpFromLine;
    } else if (position === 'end') {
      Icon = ArrowDownFromLine;
    }

    const collapsedLinesCount = chunk.lines.length - displayed.length + 1;

    return (
      <Fragment>
        {displayed.map(({ collapsed, line }) => {
          if (collapsed) {
            return (
              <button
                key={lineKey(line)}
                className={css.line}
                data-type={line.type}
                aria-expanded={false}
                disabled={disabled}
                onClick={expand}
              >
                <span className={css.expand}>
                  <Icon className={css.expandIcon} aria-hidden />

                  <span className="sr-only">Expand </span>
                </span>

                <span className={css.line} data-type={line.type}>
                  <span className={css.lineContent}>
                    <span className={cn(css.lineText, css.lineCount)}>
                      {collapsedLinesCount} unmodified line
                      {collapsedLinesCount !== 1 && 's'}
                    </span>
                  </span>
                </span>
              </button>
            );
          } else {
            return <Line key={lineKey(line)} line={line} />;
          }
        })}
      </Fragment>
    );
  }
);
