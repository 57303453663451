import React from 'react';
import cn from 'classnames';
import { HTMLMotionProps, motion } from 'motion/react';
import transitions from '@utils/transitions';
import styles from './Card.module.css';
import { CardSection } from './CardSection';

const variants = {
  initial: { y: 32, opacity: 0 },
  enter: { y: 0, opacity: 1, transition: transitions.spring },
  exit: { y: -24, opacity: 0, transition: transitions.spring },
};

type CardProps = HTMLMotionProps<'div'> & {
  transparent?: boolean;
  variant?: string;
};

export function Card({
  children,
  className,
  variant,
  transparent = false,
  ...props
}: CardProps) {
  return (
    <motion.div
      className={cn(!transparent && styles.card, className)}
      variants={variants}
      data-variant={variant}
      {...props}
    >
      {children}
    </motion.div>
  );
}

Card.Section = CardSection;
