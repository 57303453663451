import css from './Layout.module.css';
import { motion } from 'motion/react';
import LayoutErrorBoundary from './LayoutErrorBoundary';
import { Suspense } from 'react';
import LayoutLoader from './LayoutLoader';

export default function LayoutBody({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}) {
  return (
    <main className={css.main}>
      <div className={css.container}>
        <LayoutErrorBoundary>
          <motion.div
            initial={{ opacity: 0.1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Suspense fallback={fallback || <LayoutLoader />}>
              {children}
            </Suspense>
          </motion.div>
        </LayoutErrorBoundary>
      </div>
    </main>
  );
}
