import { MenuContext } from '.';
import { MouseEvent, useContext } from 'react';
import styles from './styles.module.css';
import { motion, AnimatePresence } from 'motion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cn, easeOutSmooth } from '@vault/utilities';

export function MenuList({
  className = '',
  children,
  align = 'end',
  ...props
}: DropdownMenu.DropdownMenuContentProps) {
  const open = useContext(MenuContext);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <AnimatePresence>
      {open && (
        <DropdownMenu.Portal forceMount>
          <DropdownMenu.Content
            asChild
            align={align}
            sideOffset={3}
            collisionPadding={10}
            {...props}
          >
            <motion.div
              {...ANIMATIONS}
              className={cn(styles.menu, className)}
              onClick={handleClick}
            >
              <AnimatePresence initial={false} mode="sync">
                {children}
              </AnimatePresence>
            </motion.div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </AnimatePresence>
  );
}

const ANIMATIONS = {
  initial: {
    opacity: 0,
    scale: 0.97,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.97,
    transition: { duration: 0.1 },
  },
  transition: {
    duration: 0.2,
    ease: easeOutSmooth,
  },
};
