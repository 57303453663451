import Switch from '@common/Switch/Switch';
import { Button, ButtonProps, Tooltip } from '@vault';
import { useParams } from 'react-router';
import { USER_ROLES } from '@utils/roles';
import { userTeamsSelector, useUserQuery } from '@queries/user';
import { ComponentPropsWithoutRef } from 'react';

export const PERMISSIONS_TOOLTIP =
  'You do not have the correct permissions for this action, please contact the owner of your team.';

function useTeamRole(teamUuid: string) {
  const { data: teams } = useUserQuery({ select: userTeamsSelector });
  const team = (teams ?? []).find((t) => t.uuid === teamUuid);
  return USER_ROLES.find((r) => r.value === team?.roleId);
}

function useSuperUser() {
  const { data: user } = useUserQuery();
  const roleName = user?.roleName;
  return ['superuser', 'support'].includes(roleName);
}

export function useHasRole(role: number | number[], team?: string) {
  const params = useParams<{ teamUuid?: string }>();
  const teamUuid = (team ?? params.teamUuid) as string;
  const userRole = useTeamRole(teamUuid);
  const isSuperUser = useSuperUser();

  if (isSuperUser) return true;
  if (!userRole) return false;

  if (Array.isArray(role)) {
    return role.includes(userRole?.value);
  }

  return userRole?.value === role;
}

type RestrictedButtonProps = ButtonProps & {
  roles?: number | number[];
  disabled?: boolean;
  team?: string;
};

function RestrictedButton({
  roles = [],
  disabled,
  team,
  ...props
}: RestrictedButtonProps) {
  const isPermitted = useHasRole(roles, team);

  if (isPermitted) return <Button disabled={disabled} {...props} />;

  return (
    <Tooltip content={PERMISSIONS_TOOLTIP}>
      <Button {...props} disabled={!isPermitted || disabled} />
    </Tooltip>
  );
}

type RestrictedSwitchProps = Omit<
  ComponentPropsWithoutRef<typeof Switch>,
  'disabled'
> & {
  roles?: number[];
  disabled?: boolean;
  team?: string;
};

function RestrictedSwitch({
  roles = [],
  disabled,
  team,
  ...props
}: RestrictedSwitchProps) {
  const isPermitted = useHasRole(roles, team);

  if (isPermitted) return <Switch {...props} />;

  return (
    <Tooltip content={PERMISSIONS_TOOLTIP}>
      <Switch {...props} disabled={!isPermitted || disabled} />
    </Tooltip>
  );
}

export const Restricted = {
  Button: RestrictedButton,
  Switch: RestrictedSwitch,
};
