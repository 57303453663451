import { DiffLine } from '@vault/Diff/utilities';
import { Fragment } from 'react';
import css from './styles.module.css';
import { cn } from '@vault/utilities';

export interface LineTextProps {
  line: DiffLine;
  highlight?: boolean;
}

export function LineText({ line, highlight = true }: LineTextProps) {
  switch (line.type) {
    case 'common':
      return <span className={css.lineText}>{line.content}</span>;

    case 'added':
    case 'removed':
      return (
        <span className={css.lineText}>
          {line.blocks.length > 0
            ? line.blocks.map((block, index) => (
                <span
                  key={`${index}-${block.content}`}
                  className={cn(
                    block.highlighted && highlight && css.highlight
                  )}
                >
                  <span className={css.content}>{block.content}</span>
                </span>
              ))
            : line.content}
        </span>
      );
  }
}

export interface LineContentProps {
  line: DiffLine;
  hideSymbol?: boolean;
  highlight?: boolean;
}

export function LineContent({ line, hideSymbol, highlight }: LineContentProps) {
  return (
    <span className={css.lineContent}>
      {!hideSymbol && (
        <span className={css.lineSymbol}>
          {line.type === 'added' ? (
            '+'
          ) : line.type === 'removed' ? (
            '-'
          ) : (
            <Fragment>&nbsp;</Fragment>
          )}
        </span>
      )}

      <LineText line={line} highlight={highlight} />
    </span>
  );
}

export interface LineProps {
  line: DiffLine;
  highlight?: boolean;
}

export function Line({ line, highlight }: LineProps) {
  return (
    <span className={css.line} data-type={line.type}>
      <span className={css.lineNumber} data-id="line-number-original">
        {line.type === 'common' || line.type === 'removed' ? (
          <span className={css.lineNumberText}>{line.numbers.original}</span>
        ) : null}
      </span>

      <span className={css.lineNumber} data-id="line-number-updated">
        {line.type === 'common' || line.type === 'added' ? (
          <span className={css.lineNumberText}>{line.numbers.updated}</span>
        ) : null}
      </span>

      <LineContent line={line} highlight={highlight} />
    </span>
  );
}
