import { forwardRef } from 'react';
import css from './styles.module.css';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cn } from '@vault/utilities';

export const MenuLabel = forwardRef<
  HTMLDivElement,
  DropdownMenu.DropdownMenuLabelProps
>(function MenuLabel({ className, ...props }, ref) {
  return (
    <DropdownMenu.Label
      ref={ref}
      className={cn(css.menuLabel, className)}
      {...props}
    />
  );
});
