import { diffLines, diffWords } from 'diff';

export const LINE_HEIGHT = 28;

export interface DiffChangeBlock {
  highlighted: boolean;
  content: string;
}

export interface BaseDiffLine {
  number: number;
  numbers: {
    original: number;
    updated: number;
  };
}

export interface CommonDiffLine extends BaseDiffLine {
  type: 'common';
  content: string;
}

export interface AddedDiffLine extends BaseDiffLine {
  type: 'added';
  content: string;
  blocks: DiffChangeBlock[];
}

export interface RemovedDiffLine extends BaseDiffLine {
  type: 'removed';
  content: string;
  blocks: DiffChangeBlock[];
}

export type DiffLine = CommonDiffLine | AddedDiffLine | RemovedDiffLine;

export interface DiffChunk {
  type: 'common' | 'added' | 'removed';
  lines: DiffLine[];
  number: number;
}

interface InternalDiffLineMap {
  [line: number]: DiffLine[];
}

/**
 * Remove trailing newlines from a string
 * @param value The string to change
 * @returns The string without trailing newlines
 */
function removeTrailingNewline(value: string) {
  return value.replace(/\n+$/, '');
}

/**
 * Get the word diff between two lines
 * @param original The original line
 * @param updated The updated line
 * @returns The word diff between the two lines
 */
function getLineDiff(original: string, updated: string): DiffChangeBlock[] {
  const diff = diffWords(original, updated, { ignoreWhitespace: false });

  const blocks = diff.flatMap((change) => {
    const isCommon = !change.added && !change.removed;
    const isIncluded = change.added || isCommon;
    if (!isIncluded) {
      return [];
    }

    return {
      content: change.value,
      highlighted: !isCommon,
    };
  });

  if (blocks.length === 1) {
    return [
      {
        content: blocks[0].content,
        highlighted: false,
      },
    ];
  }

  return blocks;
}

/**
 * Given a diff map, this function will clean it up by:
 * - Merging duplicate added/removed lines into common lines
 * - Sorting the diff by line number
 * - Grouping consecutive added/removed lines
 * @param diff The diff map to clean
 * @returns The cleaned diff sections
 */
function cleanDiff(diff: InternalDiffLineMap): DiffLine[] {
  const cleaned: DiffLine[] = [];

  for (const lines of Object.values(diff) as DiffLine[][]) {
    // If only one section for the line number, push it
    if (lines.length === 1) {
      cleaned.push(lines[0]);
      continue;
    }

    // Otherwise, check if the sections have unique content
    const values = lines.map((line) => line.content);
    const uniqueValues = new Set(values);

    if (uniqueValues.size === 1) {
      // If the sections have the same content, push a common section
      cleaned.push({
        type: 'common',
        number: lines[0].number,
        numbers: {
          original: lines[0].numbers.original,
          updated: lines[0].numbers.updated,
        },
        content: Array.from(uniqueValues).join(''),
      });
    } else {
      // Otherwise, push the sections as is
      cleaned.push(...lines);
    }
  }

  // Sort and group in a single pass
  const result: DiffLine[] = [];
  let changeGroup: DiffLine[] = [];

  // First sort by line number
  const sorted = cleaned.sort((a, b) => a.number - b.number);
  for (const line of sorted) {
    if (line.type === 'common') {
      // If we have pending changes, sort and flush them
      if (changeGroup.length > 0) {
        // Sort removed before added
        changeGroup.sort((a, b) =>
          a.type === 'removed' && b.type === 'added'
            ? -1
            : a.type === 'added' && b.type === 'removed'
              ? 1
              : 0
        );
        result.push(...changeGroup);
        changeGroup = [];
      }
      result.push(line);
    } else {
      // Accumulate changes
      changeGroup.push(line);
    }
  }
  // Flush any remaining changes
  if (changeGroup.length > 0) {
    changeGroup.sort((a, b) =>
      a.type === 'removed' && b.type === 'added'
        ? -1
        : a.type === 'added' && b.type === 'removed'
          ? 1
          : 0
    );
    result.push(...changeGroup);
  }

  return result;
}

/**
 * Get the diff between two documents
 * @param original The original document
 * @param updated The updated document
 * @returns The diff between the two documents
 */
export function getDocumentDiff(original: string, updated: string): DiffLine[] {
  const diff = diffLines(original, updated);

  let number = 1; // originalNumber?
  let originalNumber = 1;
  let updatedNumber = 1;

  const lineMap: InternalDiffLineMap = {};
  for (let index = 0; index < diff.length; index++) {
    const change = diff[index];

    const isCommon = !change.added && !change.removed;
    if (isCommon) {
      // Push common lines
      const commonLines = removeTrailingNewline(change.value).split('\n');
      commonLines.forEach((value, lineIndex) => {
        lineMap[number + lineIndex] ??= [];
        lineMap[number + lineIndex].push({
          type: 'common',
          number: number + lineIndex,
          numbers: {
            original: originalNumber + lineIndex,
            updated: updatedNumber + lineIndex,
          },
          content: value,
        });
      });

      const lineCount = change.count ?? 1;
      number += lineCount;
      originalNumber += lineCount;
      updatedNumber += lineCount;

      continue;
    }

    // If there is no next change or the next change is common,
    // Push the removed or added lines without highlighting
    const nextChange = diff.at(index + 1);
    const isNextCommon = nextChange && !nextChange.added && !nextChange.removed;
    if (!nextChange || isNextCommon) {
      const type = change.removed ? 'removed' : 'added';
      const lines = removeTrailingNewline(change.value).split('\n');
      lines.forEach((value, lineIndex) => {
        lineMap[number + lineIndex] ??= [];
        lineMap[number + lineIndex].push({
          type,
          number: number + lineIndex,
          numbers: {
            original: originalNumber + lineIndex,
            updated: updatedNumber + lineIndex,
          },
          content: value,
          blocks: [],
        });
      });

      const lineCount = change.count ?? 1;
      number += lineCount;
      if (type === 'removed') originalNumber += lineCount;
      if (type === 'added') updatedNumber += lineCount;

      continue;
    }

    const originalLines = removeTrailingNewline(change.value).split('\n');
    const updatedLines = removeTrailingNewline(nextChange.value).split('\n');

    const maxIndex = Math.max(originalLines.length, updatedLines.length);

    // Push removed lines with highlighting
    for (let lineIndex = 0; lineIndex < maxIndex; lineIndex++) {
      const originalLine = originalLines.at(lineIndex);
      const updatedLine = updatedLines.at(lineIndex);
      if (originalLine === undefined) continue;
      lineMap[number + lineIndex] ??= [];
      lineMap[number + lineIndex].push({
        type: 'removed',
        number: number + lineIndex,
        numbers: {
          original: originalNumber + lineIndex,
          updated: updatedNumber + lineIndex,
        },
        content: originalLine,
        blocks: getLineDiff(updatedLine ?? '', originalLine), // TODO: only calculate if needed
      });
    }

    // Push added lines with highlighting
    for (let lineIndex = 0; lineIndex < maxIndex; lineIndex++) {
      const originalLine = originalLines.at(lineIndex);
      const updatedLine = updatedLines.at(lineIndex);
      if (updatedLine === undefined) continue;
      lineMap[number + lineIndex] ??= [];
      lineMap[number + lineIndex].push({
        type: 'added',
        number: number + lineIndex,
        numbers: {
          original: originalNumber + lineIndex,
          updated: updatedNumber + lineIndex,
        },
        content: updatedLine,
        blocks: getLineDiff(originalLine ?? '', updatedLine), // TODO: only calculate if needed
      });
    }

    number += maxIndex;
    originalNumber += originalLines.length;
    updatedNumber += updatedLines.length;
    index++;
  }

  return cleanDiff(lineMap);
}

/**
 * Groups consecutive added/removed lines into chunks.
 * @param diff The diff sections to group
 * @returns The grouped diff sections
 */
export function chunkDiff(diff: DiffLine[]): DiffChunk[] {
  const chunks: DiffChunk[] = [];
  if (!diff.length) return chunks;

  let currentChunk: DiffChunk | null = null;

  for (const line of diff) {
    if (!currentChunk) {
      currentChunk = {
        type: line.type,
        lines: [line],
        number: line.number,
      };
      continue;
    }

    if (line.type === currentChunk.type) {
      currentChunk.lines.push(line);
      continue;
    }

    chunks.push(currentChunk);
    currentChunk = {
      type: line.type,
      lines: [line],
      number: line.number,
    };
  }

  if (currentChunk) chunks.push(currentChunk);

  return chunks;
}
