import { UpgradeModal } from './UpgradeModal';
import { useDialog } from '@common/Dialog';
import css from './UpgradeButton.module.css';
import { Tag } from '@common/Tag/Tag';

type UpgradeButtonProps = {
  teamUuid: string;
};

export function UpgradeButton({ teamUuid }: UpgradeButtonProps) {
  const dialog = useDialog();

  return (
    <>
      <UpgradeModal state={dialog} teamUuid={teamUuid} />
      <button onClick={dialog.open} className={css.button}>
        Upgrade
        <Tag data-size="xs" data-tier="pro" className="mx-1">
          Pro
        </Tag>
      </button>
    </>
  );
}
