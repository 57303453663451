import { Margin } from '@vault/SteppedLineChart/types';
import {
  Dispatch,
  Ref,
  RefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

const DEFAULT_MARGINS: Margin = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export function useMargins(
  defaultMargins: Partial<Margin>,
  xAxisRef: RefObject<SVGSVGElement> | null,
  yAxisRef: RefObject<SVGSVGElement> | null
) {
  const [margins, setMargins] = useState({
    ...DEFAULT_MARGINS,
    ...defaultMargins,
  });

  useLayoutEffect(() => {
    let xObserver: ResizeObserver | null = null;
    if (!xAxisRef) {
      setMargins((prev) => ({
        ...prev,
        bottom: defaultMargins.bottom ?? DEFAULT_MARGINS.bottom,
      }));
    } else if (xAxisRef.current) {
      xObserver = new ResizeObserver((entries) => {
        setMargins((prev) => ({
          ...prev,
          bottom: entries[0].contentRect.height + 10,
        }));
      });
      xObserver.observe(xAxisRef.current);
    }

    let yObserver: ResizeObserver | null = null;
    if (!yAxisRef) {
      setMargins((prev) => ({
        ...prev,
        left: defaultMargins.left ?? DEFAULT_MARGINS.left,
      }));
    } else if (yAxisRef.current) {
      yObserver = new ResizeObserver((entries) => {
        setMargins((prev) => ({
          ...prev,
          left: entries[0].contentRect.width + 10,
        }));
      });
      yObserver.observe(yAxisRef.current);
    }

    return () => {
      if (xObserver) xObserver.disconnect();
      if (yObserver) yObserver.disconnect();
    };
  }, [xAxisRef, yAxisRef]);

  return margins;
}
