import css from './Layout.module.css';
import { Link, Location, useLocation } from 'react-router';
import { ArrowLeft } from 'lucide-react';
import { ReactNode } from 'react';

export function LayoutBackButton({
  children,
  fallbackTo,
}: {
  children: ReactNode;
  fallbackTo: string | Location;
}) {
  const location = useLocation();
  const to = location?.state?.back || fallbackTo;

  return (
    <Link className={css.back} to={to}>
      <div className={css.backIcon}>
        <ArrowLeft />
      </div>
      {children}
    </Link>
  );
}
