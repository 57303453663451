import { Badge, easeOutExpo, Flex, Link, TaggedBanner, Text } from '@vault';
import { FlaskConical } from 'lucide-react';
import MixedList from '../Common/MixedList/MixedList';
import css from './styles.module.css';
import { useCurrentApp } from '@queries/user';
import { AnimatePresence, cubicBezier, motion } from 'motion/react';

const SandboxIcon = FlaskConical;

function SandboxIndicator() {
  return <Badge icon={<SandboxIcon />}>Sandbox</Badge>;
}

function SandboxTooltip() {
  return (
    <Flex className={css.tooltip} align="start" direction="column" gap="8px">
      <Text size="sm" leading="md" color="var(--grey-60)">
        Sandbox apps let you test Evervault enterprise features without
        affecting production data.
      </Text>

      <Link external href="https://docs.evervault.com/more/sandbox">
        <Text size="sm" weight={500} color="var(--primary)">
          Learn more
        </Text>
      </Link>
    </Flex>
  );
}

function SandboxBanner() {
  const { app } = useCurrentApp();
  const isSandbox = !!app?.sandbox;

  return (
    <AnimatePresence initial={false}>
      {isSandbox && (
        <motion.div
          className={css.banner}
          initial={{ height: 0 }}
          animate={{ height: 24 }}
          exit={{ height: 0 }}
          transition={{ duration: 0.4, ease: easeOutExpo }}
        >
          <TaggedBanner
            text="Sandbox"
            icon={SandboxIcon}
            tooltip={<SandboxTooltip />}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

interface SandboxInfoProps {
  isSandbox: boolean;
}

function SandboxInfo({ isSandbox }: SandboxInfoProps) {
  return (
    <MixedList.Row>
      <MixedList.Data>
        <h6>App mode</h6>
        <p>{isSandbox ? 'Sandbox' : 'Production'}</p>
      </MixedList.Data>
    </MixedList.Row>
  );
}

export const Sandbox = {
  Icon: SandboxIcon,
  Indicator: SandboxIndicator,
  Banner: SandboxBanner,
  Info: SandboxInfo,
  Tooltip: SandboxTooltip,
};
