import {
  SteppedLineChartAnnotation,
  SteppedLineChartData,
} from '@vault/SteppedLineChart';
import { ReactNode } from 'react';
import css from './styles.module.css';

export type TooltipData =
  | {
      type: 'data';
      index: number;
    }
  | {
      type: 'annotation';
      index: number;
      seriesIndex: number;
    };

export type RenderSteppedLineChartTooltipFn<
  Data extends SteppedLineChartData,
  Annotation extends SteppedLineChartAnnotation,
> = {
  /** The tooltip to display when hovering over a data point. */
  (value: { type: 'data'; data: Data[]; index: number }): ReactNode;
  /** The tooltip to display when hovering over an annotation. */
  (value: {
    type: 'annotation';
    data: Annotation;
    seriesIndex: number;
    index: number;
  }): ReactNode;
};

export interface TooltipProps<
  Data extends SteppedLineChartData,
  Annotation extends SteppedLineChartAnnotation,
> {
  tooltip: TooltipData;
  data: Data[][];
  annotations?: Annotation[][];
  renderTooltip: RenderSteppedLineChartTooltipFn<Data, Annotation>;
}

export function Tooltip<
  Data extends SteppedLineChartData,
  Annotation extends SteppedLineChartAnnotation,
>({
  tooltip,
  data,
  annotations,
  renderTooltip,
}: TooltipProps<Data, Annotation>) {
  if (tooltip.type === 'annotation') {
    const annotation = annotations?.at(tooltip.seriesIndex)?.at(tooltip.index);
    if (!annotation) return null;
    const node = renderTooltip({
      type: 'annotation',
      data: annotation,
      seriesIndex: tooltip.seriesIndex,
      index: tooltip.index,
    });
    if (!node) return null;
    return <div className={css.tooltip}>{node}</div>;
  }

  const dataPts = data.flatMap((series) => series.at(tooltip.index) ?? []);
  if (dataPts.length !== data.length) return null;
  const node = renderTooltip({
    type: 'data',
    data: dataPts,
    index: tooltip.index,
  });
  if (!node) return null;
  return <div className={css.tooltip}>{node}</div>;
}
