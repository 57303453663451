import { AnimatePresence, easeOut, motion } from 'motion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import styles from './styles.module.css';
import { easeOutSmooth } from '@vault/utilities';
import { Check } from 'lucide-react';
import { AnimatedIcon } from '@vault/AnimatedIcon';

export function MenuCheckbox({
  className,
  children,
  checked,
  ...props
}: DropdownMenu.DropdownMenuCheckboxItemProps) {
  return (
    <DropdownMenu.CheckboxItem
      className={classNames(styles.menuItem, className)}
      checked={checked}
      onSelect={(e) => e.preventDefault()}
      {...props}
    >
      <div
        className={styles.checkbox}
        aria-checked={checked === 'indeterminate' ? 'mixed' : checked}
      >
        <AnimatePresence initial={false}>
          {checked && (
            <motion.div className={styles.check}>
              <AnimatedIcon.Check
                strokeWidth={4}
                initial="from"
                animate="to"
                transition={{ duration: 0.2, delay: 0.02, ease: easeOutSmooth }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <span className={styles.label}>{children}</span>
    </DropdownMenu.CheckboxItem>
  );
}
