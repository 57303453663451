import * as RadixTabs from '@radix-ui/react-tabs';
import { AnimatePresence, LayoutGroup } from 'motion/react';
import { ComponentProps, ReactElement, useId, useMemo, useState } from 'react';
import css from './styles.module.css';
import { Tab } from './Tab';
import { cn } from '@vault/utilities';
import { Trigger } from './Trigger';
import { TabsContext } from './Context';

type TabInstance = ReactElement<ComponentProps<typeof Tab>>;
type TabsProps = RadixTabs.TabsProps & {
  children: TabInstance | TabInstance[];
  className?: string;
};

function Tabs({
  children,
  className,
  value,
  onValueChange,
  ...props
}: TabsProps) {
  const id = useId();
  const childrenArray = Array.isArray(children) ? children : [children];
  const filteredChildren = childrenArray.filter(Boolean);
  const [internalValue, setValue] = useState(filteredChildren[0].props.label);

  const context = useMemo(() => {
    return { value: value || internalValue };
  }, [internalValue, value]);

  return (
    <TabsContext.Provider value={context}>
      <LayoutGroup id={id}>
        <RadixTabs.Root
          value={value || internalValue}
          onValueChange={onValueChange || setValue}
          {...props}
        >
          <RadixTabs.List className={css.list}>
            <AnimatePresence>
              <div className={cn(css.tabs, className)}>
                {filteredChildren.map((child) => (
                  <Trigger
                    key={child.props.label}
                    label={child.props.label}
                    icon={child.props.icon}
                  />
                ))}
              </div>
            </AnimatePresence>
          </RadixTabs.List>
          <AnimatePresence mode="wait" initial={false}>
            <div>{children}</div>
          </AnimatePresence>
        </RadixTabs.Root>
      </LayoutGroup>
    </TabsContext.Provider>
  );
}

const TabsNamespace = Object.assign(Tabs, { Tab });
export { TabsNamespace as Tabs };
