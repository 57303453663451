import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';
import { AppError } from '@common/AppError/AppError';
import { Card } from '@common/Card/Card';
import { IconRefresh } from '@common/Icons/Icons';
import LayoutLoader from './LayoutLoader';

function LayoutFallback({ error }) {
  const location = useLocation();

  const handleReset = () => {
    window.location = location.pathname;
  };

  if (error.message.includes('Failed to fetch dynamically imported module')) {
    return <LayoutLoader />;
  }

  return (
    <Card className="p-5" style={{ maxWidth: 560, margin: '0 auto' }}>
      <AppError
        onRetry={handleReset}
        buttonLabel="Try again"
        buttonIcon={IconRefresh}
      >
        There was an error while loading this page. We’ve been notified and are
        working to get things back to normal quickly.
      </AppError>
    </Card>
  );
}

export default function LayoutErrorBoundary({ children }) {
  const location = useLocation();

  return (
    <Sentry.ErrorBoundary
      key={location.pathname}
      fallback={({ error }) => <LayoutFallback error={error} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
