import { usePaginationContext } from '.';
import { PaginationButton } from './PaginationButton';

export function PageButton({ page }: { page: number }) {
  const ctx = usePaginationContext();
  const isActive = ctx.page === page;

  const handleClick = () => {
    ctx.onPageChange(page);
  };

  return (
    <PaginationButton
      onClick={handleClick}
      isActive={isActive}
      aria-label={`Page ${page}`}
    >
      {page}
    </PaginationButton>
  );
}
