import format from '@utils/format';
import {
  Activity,
  ChevronsUpDown,
  Fingerprint,
  Landmark,
  Plus,
  Settings,
  Users,
} from 'lucide-react';
import { HeaderDropdown } from '../HeaderDropdown';
import { useMemo } from 'react';
import { avatar } from '@utils/generateAvatar';
import css from './styles.module.css';
import { useLocation, useParams } from 'react-router';
import { Tag } from '@common/Tag/Tag';
import Dialog, { useDialog } from '@common/Dialog';
import { TeamCreate } from '@routes/TeamSettings/Settings/TeamCreate/TeamCreate';
import { useCurrentTeam } from '@queries/user';
import { cn, ScrollArea } from '@vault';

function TeamAvatar({ team }) {
  const src = useMemo(() => avatar(team.name), [team]);
  return <img className={css.avatar} src={src} />;
}

function TeamTier({ team }) {
  return (
    <Tag data-size="xs" data-tier={team?.tier}>
      {format.capitalizeFirstLetter(team?.tier)}
    </Tag>
  );
}

export function TeamSwitcher({ user }) {
  const location = useLocation();
  const createTeamDialog = useDialog();
  const { teamUuid } = useParams();
  const teams = user?.teams || [];
  const { team: currentTeam } = useCurrentTeam();

  const other = useMemo(
    () => teams.filter((team) => team.uuid !== teamUuid),
    [teams, teamUuid]
  );

  return (
    <>
      <Dialog.Modal title="Create Team" state={createTeamDialog}>
        <TeamCreate />
      </Dialog.Modal>
      <HeaderDropdown>
        <HeaderDropdown.Trigger className={cn(css.breadcrumb, css.truncate)}>
          <span className={css.breadcrumbText}>
            {currentTeam?.name || 'Teams'}
          </span>
          <ChevronsUpDown aria-hidden />
        </HeaderDropdown.Trigger>
        <HeaderDropdown.Content>
          {currentTeam && (
            <>
              <HeaderDropdown.Item
                disabled
                prefix={<TeamAvatar team={currentTeam} />}
                suffix={<TeamTier team={currentTeam} />}
              >
                {currentTeam?.name}
              </HeaderDropdown.Item>
              <HeaderDropdown.Separator />
              <div className="py-1">
                <HeaderDropdown.Link
                  prefix={<Settings />}
                  to={`/${teamUuid}/settings/general`}
                  state={{ back: location }}
                >
                  Settings
                </HeaderDropdown.Link>
                <HeaderDropdown.Link
                  prefix={<Fingerprint />}
                  to={`/${teamUuid}/settings/security`}
                  state={{ back: location }}
                >
                  Security
                </HeaderDropdown.Link>
                <HeaderDropdown.Link
                  prefix={<Users />}
                  to={`/${teamUuid}/settings/members`}
                  state={{ back: location }}
                >
                  Members
                </HeaderDropdown.Link>
                <HeaderDropdown.Link
                  prefix={<Activity />}
                  to={`/${teamUuid}/settings/activity`}
                  state={{ back: location }}
                >
                  Activity
                </HeaderDropdown.Link>
                <HeaderDropdown.Link
                  prefix={<Landmark />}
                  to={`/${teamUuid}/settings/billing`}
                  state={{ back: location }}
                >
                  Billing
                </HeaderDropdown.Link>
              </div>
              <HeaderDropdown.Separator />
            </>
          )}
          <div className={css.teams}>
            <ScrollArea>
              {other.length > 0 && (
                <>
                  <HeaderDropdown.Label>Switch Team</HeaderDropdown.Label>
                  {other.map((team) => (
                    <HeaderDropdown.Link
                      key={team.uuid}
                      to={`/${team.uuid}/${team.apps?.[0]?.uuid || ''}`}
                      prefix={<TeamAvatar team={team} />}
                      suffix={<TeamTier team={team} />}
                    >
                      {team.name}
                    </HeaderDropdown.Link>
                  ))}
                  <HeaderDropdown.Separator />
                </>
              )}
              <HeaderDropdown.Item
                prefix={<Plus />}
                onSelect={createTeamDialog.open}
              >
                New Team
              </HeaderDropdown.Item>
            </ScrollArea>
          </div>
        </HeaderDropdown.Content>
      </HeaderDropdown>
    </>
  );
}
