import { PropsWithChildren, ReactNode } from 'react';
import css from './styles.module.css';
import { cn } from '@vault/utilities';

interface ItemProps extends PropsWithChildren {
  title: string;
  hideTitle?: boolean;
  icon?: ReactNode;
  suffix?: ReactNode;
}

function Item({ title, hideTitle, icon, suffix, children }: ItemProps) {
  return (
    <div className={css.item}>
      {icon && (
        <span className={css.icon} aria-hidden>
          {icon}
        </span>
      )}
      <dt className={cn(css.title, hideTitle && 'sr-only')}>{title}</dt>
      <dd className={css.value}>{children}</dd>
      {suffix && <span className={css.suffix}>{suffix}</span>}
    </div>
  );
}

interface RootProps extends PropsWithChildren {
  className?: string;
}

function Root({ children, className }: RootProps) {
  return <dl className={cn(css.root, className)}>{children}</dl>;
}

export const PropertyList = Object.assign(Root, {
  Item,
});
