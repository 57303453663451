import { AnimatePresence, motion } from 'motion/react';
import * as Primitive from '@radix-ui/react-dropdown-menu';
import css from './styles.module.css';
import { createContext, forwardRef, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { ScrollArea } from '@vault';

const Context = createContext({
  open: false,
});

type HeaderDropdownProps = {
  children: React.ReactNode;
};

export function HeaderDropdown({ children }: HeaderDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Context.Provider value={{ open: isOpen }}>
      <Primitive.Root open={isOpen} onOpenChange={setIsOpen}>
        {children}
      </Primitive.Root>
    </Context.Provider>
  );
}

HeaderDropdown.Trigger = forwardRef<
  HTMLButtonElement,
  Primitive.DropdownMenuTriggerProps
>(function HeaderDropdownTrigger({ children, className, ...props }, ref) {
  const classes = [css.trigger, className].filter(Boolean).join(' ');

  return (
    <Primitive.Trigger ref={ref} className={classes} {...props}>
      {children}
    </Primitive.Trigger>
  );
});

HeaderDropdown.Content = forwardRef<
  HTMLDivElement,
  Primitive.DropdownMenuContentProps
>(function HeaderDropdownContent(
  { children, align = 'start', sideOffset = 8, ...props },
  ref
) {
  const { open } = useContext(Context);

  return (
    <Primitive.Portal forceMount>
      <div>
        <AnimatePresence>
          {open && (
            <Primitive.Content
              forceMount
              ref={ref}
              align={align}
              sideOffset={sideOffset}
              {...props}
              asChild
            >
              <motion.div
                data-align={align}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                transition={{ duration: 0.2 }}
                className={css.dropdown}
              >
                <ScrollArea>{children}</ScrollArea>
              </motion.div>
            </Primitive.Content>
          )}
        </AnimatePresence>
      </div>
    </Primitive.Portal>
  );
});

type HeaderDropdownItemProps = Omit<
  Primitive.DropdownMenuItemProps,
  'prefix'
> & {
  prefix: React.ReactNode;
  suffix?: React.ReactNode;
};

HeaderDropdown.Item = forwardRef<HTMLDivElement, HeaderDropdownItemProps>(
  function HeaderDropdownItem(
    { children, prefix, suffix, className, ...props },
    ref
  ) {
    const classes = [css.item, className].filter(Boolean).join(' ');

    return (
      <Primitive.Item ref={ref} className={classes} {...props}>
        {prefix && <span className={css.prefix}>{prefix}</span>}
        <span className={css.itemLabel}>{children}</span>
        {suffix && <span className={css.suffix}>{suffix}</span>}
      </Primitive.Item>
    );
  }
);

type HeaderDropdownLinkProps = Omit<HeaderDropdownItemProps, 'onSelect'> & {
  to: string;
  state?: any;
  replace?: boolean;
};

HeaderDropdown.Link = forwardRef<HTMLDivElement, HeaderDropdownLinkProps>(
  function HeaderDropdownLink({ to, state, replace, ...props }, ref) {
    const navigate = useNavigate();

    const handleSelect = () => {
      navigate(to, { state, replace });
    };

    return <HeaderDropdown.Item ref={ref} {...props} onSelect={handleSelect} />;
  }
);

HeaderDropdown.Separator = forwardRef<
  HTMLDivElement,
  Primitive.DropdownMenuSeparatorProps
>(function HeaderDropdownSeparator({ className, ...props }, ref) {
  const classes = [css.separator, className].filter(Boolean).join(' ');
  return <Primitive.Separator ref={ref} className={classes} {...props} />;
});

HeaderDropdown.Label = forwardRef<
  HTMLDivElement,
  Primitive.DropdownMenuLabelProps
>(function HeaderDropdownLabel({ children, className, ...props }, ref) {
  const classes = [css.label, className].filter(Boolean).join(' ');
  return (
    <Primitive.Label ref={ref} className={classes} {...props}>
      {children}
    </Primitive.Label>
  );
});
