import { cn } from '@vault/utilities';
import css from './styles.module.css';
import {
  cloneElement,
  ComponentPropsWithoutRef,
  forwardRef,
  isValidElement,
  ReactNode,
  useCallback,
} from 'react';
import { Spinner } from '@vault/Spinner';
import { Slot } from '@radix-ui/react-slot';
import { FocusRing } from '@vault/FocusRing';

type BaseButtonProps = Omit<ComponentPropsWithoutRef<'button'>, 'prefix'>;

export interface ButtonProps extends BaseButtonProps {
  /** Use child component as the root element of the button */
  asChild?: boolean;
  /** The size of the button */
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg';
  /** Whether the button is busy */
  loading?: boolean;
  /** Color variant */
  variant?: 'primary' | 'secondary' | 'danger';
  /** Whether the button is transparent */
  ghost?: boolean;
  /** Whether to flush the button with its parent container (only applies to ghost buttons) */
  flush?: 'x' | 'y' | 'xy';
  /** A leading node (e.g., an icon) */
  prefix?: ReactNode;
  /** A trailing node (e.g., an icon) */
  suffix?: ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      asChild,
      className,
      size = 'md',
      loading = false,
      variant = 'secondary',
      ghost = false,
      prefix,
      suffix,
      children,
      disabled,
      flush,
      ...props
    },
    ref
  ) {
    const isDisabled = disabled || loading;

    // Disable default click behavior for all types of elements (that may not respond to :disabled)
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (isDisabled) {
          e.preventDefault();
          e.stopPropagation();
        } else {
          props.onClick?.(e);
        }
      },
      [isDisabled, props.onClick]
    );

    // Check if we can render the child as a Slot
    const canRenderAsChild =
      !!asChild && !!children && isValidElement(children);
    const Component = canRenderAsChild ? Slot : 'button';

    // Override certain props for known use cases (e.g., disabled links)
    let disabledChildProps: Record<string, any> = {};
    if (isDisabled && canRenderAsChild && 'href' in children.props) {
      disabledChildProps.href = undefined;
    }

    const content = (
      <>
        {loading && (
          <span className={css.spinner} aria-hidden>
            <Spinner className={css.spinnerIcon} />
          </span>
        )}

        {prefix && (
          <span className={css.prefix} aria-hidden>
            {prefix}
          </span>
        )}

        <span className={css.label}>
          {/* If using asChild, render the grandchildren instead of the child */}
          {canRenderAsChild ? children.props.children : children}
        </span>

        {suffix && (
          <span className={css.suffix} aria-hidden>
            {suffix}
          </span>
        )}
      </>
    );

    return (
      <FocusRing>
        <Component
          ref={ref}
          className={cn(css.button, className)}
          data-size={size}
          data-variant={variant}
          data-ghost={ghost}
          data-flush={flush}
          aria-busy={loading}
          aria-disabled={isDisabled}
          disabled={isDisabled}
          onClick={props.onClick ? handleClick : undefined}
          {...props}
        >
          {/* If rendering asChild, render the button content as grandchildren */}
          {canRenderAsChild
            ? cloneElement(children, disabledChildProps, content)
            : content}
        </Component>
      </FocusRing>
    );
  }
);
