import { teamHasBillingError, useCurrentTeam } from '@queries/user';
import { Navigate } from 'react-router';

// Wraps children and redirects to the settings page if the user has a billing error.
export function BillingBounce({ children }: { children: React.ReactNode }) {
  const { team } = useCurrentTeam();

  if (teamHasBillingError(team)) {
    return <Navigate to={`/${team.uuid}/settings/billing`} replace />;
  }

  return children;
}
