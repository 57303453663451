import styles from './styles.module.css';
import { AnimatePresence, motion } from 'motion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronRight } from 'lucide-react';
import { easeOutSmooth } from '@vault/utilities';
import { useState } from 'react';

type MenuSubMenuProps = DropdownMenu.DropdownMenuSubContentProps & {
  label: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
};

export function MenuSubMenu({
  label,
  icon,
  children,
  ...props
}: MenuSubMenuProps) {
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu.Sub open={open} onOpenChange={setOpen}>
      <DropdownMenu.SubTrigger className={styles.menuItem}>
        {icon && (
          <span className={styles.icon} aria-hidden>
            {icon}
          </span>
        )}
        <span className={styles.label}>{label}</span>
        <ChevronRight className={styles.subMenuArrow} aria-hidden />
      </DropdownMenu.SubTrigger>

      <AnimatePresence>
        {open && (
          <DropdownMenu.Portal forceMount>
            <DropdownMenu.SubContent
              className={styles.menu}
              sideOffset={3}
              alignOffset={-2}
              {...props}
              asChild
            >
              <motion.div {...ANIMATIONS}>{children}</motion.div>
            </DropdownMenu.SubContent>
          </DropdownMenu.Portal>
        )}
      </AnimatePresence>
    </DropdownMenu.Sub>
  );
}

const ANIMATIONS = {
  initial: {
    opacity: 0,
    x: -2,
    scale: 0.97,
  },
  animate: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  exit: {
    opacity: 0,
    x: -2,
    scale: 0.97,
    transition: {
      duration: 0.1,
      ease: easeOutSmooth,
    },
  },
  transition: {
    duration: 0.2,
    ease: easeOutSmooth,
  },
};
