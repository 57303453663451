import { AnimatePresence, motion } from 'motion/react';
import { CopyIcon } from 'lucide-react';
import { ComponentProps } from 'react';
import { cn } from '@vault/utilities';
import css from './styles.module.css';

const MotionCopyIcon = motion(CopyIcon);

export function Copy(props: ComponentProps<typeof MotionCopyIcon>) {
  return (
    <MotionCopyIcon
      {...props}
      className={cn(css.copy, props.className)}
      variants={{
        from: {
          '--animated-copy-progress': 0,
        },
        to: {
          '--animated-copy-progress': 1,
        },
      }}
      aria-hidden
    />
  );
}
