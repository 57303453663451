import { Link, useLocation } from 'react-router';

import { Button } from '@vault';
import { Card } from '@common/Card/Card';
import { IconArrow } from '@common/Icons/Icons';
import { ArrowRight } from 'lucide-react';

export default function NotFound() {
  const { pathname } = useLocation();

  return (
    <Card className="p-5" style={{ maxWidth: 560, margin: '0 auto' }}>
      <h6 className="mb-2 color-secondary-60">404</h6>
      <h3>Page Not found</h3>
      <p>
        No page found for <code>{pathname}</code>.
      </p>
      <Button asChild suffix={<ArrowRight />}>
        <Link to="/">Take me home</Link>
      </Button>
    </Card>
  );
}
