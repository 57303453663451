import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

const isJWTError = (err) =>
  err.code === 'jwt-expired' || err.response?.code === 'jwt-expired';

const isNetworkError = (err) =>
  err.name === 'NetworkError' || err.message === 'Network request failed';

const handleError = (error, reportTag) => {
  const statusCode = error?.response?.status;

  if (
    statusCode === 401 ||
    statusCode === 403 ||
    isJWTError(error) ||
    isNetworkError(error)
  ) {
    return;
  }

  const requestUuid = error?.response?.headers?.get('x-request-id');
  Sentry.withScope((scope) => {
    scope.setTag('request-id', requestUuid);
    scope.setTag('reported_from', reportTag);
    Sentry.captureException(error);
  });
};

// Init react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry(failureCount, error) {
        if (isNetworkError(error)) {
          return false;
        }

        if (isJWTError(error)) {
          return false;
        }

        if (
          'response' in error &&
          error?.response?.status >= 400 &&
          error?.response?.status < 500
        ) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      handleError(error, 'query');
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      handleError(error, 'mutation');
    },
  }),
});

export default queryClient;
