import { forwardRef, useId } from 'react';
import styles from './styles.module.css';
import { cn } from '@vault/utilities';
import { useFocusRing } from '@vault/FocusRing';
import composeRefs from '@seznam/compose-react-refs';

export interface SwitchProps {
  value: boolean;
  onValueChange(value: boolean): void;
  'aria-label': string;
  id?: string;
  size?: 'sm' | 'lg';
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(
  { value, onValueChange, 'aria-label': ariaLabel, id: propId, size = 'sm' },
  ref
) {
  const localId = useId();
  const id = propId ?? localId;

  const [inputRef, ringStyle] = useFocusRing<HTMLInputElement>();

  return (
    <div className={cn(styles.switch, styles[size])}>
      <label htmlFor={id}>
        <span className="sr-only">{ariaLabel}</span>
        <div className={styles.track} style={ringStyle}>
          <div className={styles.thumb} />
        </div>
      </label>

      <input
        ref={composeRefs(ref, inputRef)}
        id={id}
        className="sr-only"
        type="checkbox"
        checked={value}
        onChange={(e) => onValueChange(e.target.checked)}
      />
    </div>
  );
});
