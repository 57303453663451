import { useLayoutEffect } from 'react';
import css from './styles.module.css';

export function EnvironmentBanner() {
  const environment = import.meta.env.VITE_EVERVAULT_ENVIRONMENT;
  const isEnabled = !!environment;

  useLayoutEffect(() => {
    if (isEnabled) {
      document.body.style.setProperty('--sticky-top', '32px');
    } else {
      document.body.style.removeProperty('--sticky-top');
    }
  }, [isEnabled]);

  if (!isEnabled) return null;

  return <div className={css.banner}>{environment}</div>;
}
