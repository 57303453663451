import { createContext } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useState } from 'react';
import { MenuList } from './MenuList';
import { MenuTrigger } from './MenuTrigger';
import { MenuItem } from './MenuItem';
import { MenuCheckbox } from './MenuCheckbox';
import { MenuSubMenu } from './MenuSubMenu';
import { MenuHeading } from './MenuHeading';
import { MenuLabel } from './MenuLabel';
import { MenuEmpty } from '@vault/Menu/MenuEmpty';

export const MenuContext = createContext<boolean>(false);

type MenuProps = {
  children: React.ReactNode;
};

export function Menu({ children }: MenuProps) {
  const [open, setOpen] = useState(false);

  return (
    <MenuContext.Provider value={open}>
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        {children}
      </DropdownMenu.Root>
    </MenuContext.Provider>
  );
}

Menu.Trigger = MenuTrigger;
Menu.List = MenuList;
Menu.Item = MenuItem;
Menu.Label = MenuLabel;
Menu.CheckboxItem = MenuCheckbox;
Menu.SubMenu = MenuSubMenu;
Menu.Heading = MenuHeading;
Menu.Empty = MenuEmpty;
