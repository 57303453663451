import React, { useState } from 'react';
import { AnimatePresence } from 'motion/react';
import Modal from '@common/Modal/Modal';

export const ModalContext = React.createContext();

export default function ModalProvider({ children }) {
  // Default state is no content to show
  const [modal, setModal] = useState(null);

  /**
   * Open modal with provided content and options
   * @param {React.ReactNode} content
   * @param {any} options
   */
  const openModal = (
    content,
    {
      title = '',
      isPanel = false,
      hideCloseButton = false,
      disableEscapeKeyDown = false,
      onClickNext,
      onClickPrev,
    }
  ) => {
    const modal = {
      content,
      props: {
        title,
        isPanel,
        hideCloseButton,
        disableEscapeKeyDown,
        onClickNext,
        onClickPrev,
      },
    };
    setModal(modal);
  };

  /**
   * Close modal and clear content set to it;
   * @returns void
   */
  const closeModal = () => setModal(null);

  /**
   * Display modal if openModal has been givin content
   * and options
   */
  const showModal = modal !== null && modal !== undefined;

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <AnimatePresence>
        {showModal && (
          <Modal key="modal" {...modal.props}>
            {modal.content}
          </Modal>
        )}
      </AnimatePresence>
    </ModalContext.Provider>
  );
}
