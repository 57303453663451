import { Toaster } from 'react-hot-toast';

import { IconBadgeError, IconBadgeSuccess } from '@common/Icons/Icons';
import { Spinner } from '@common/Spinner/Spinner';

export default function ToastContainer() {
  return (
    <div data-testid="toast">
      <Toaster
        position="bottom-center"
        containerStyle={{ bottom: 32 }}
        toastOptions={{
          className: 'py-2 px-2 color-body',
          style: { boxShadow: '0 6px 12px rgb(0 0 20 / 12%)' },
          loading: {
            icon: (
              <Spinner
                width="1px"
                size="var(--token-16)"
                color="var(--grey-100)"
                className="flex-shrink-0 ml-1"
              />
            ),
          },
          success: {
            icon: (
              <IconBadgeSuccess className="flex-shrink-0 ml-1 color-primary" />
            ),
          },
          error: {
            icon: (
              <IconBadgeError className="flex-shrink-0 ml-1 color-red-medium" />
            ),
          },
        }}
      />
    </div>
  );
}
