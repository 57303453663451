import { ChevronLeft, ChevronRight } from 'lucide-react';
import css from './styles.module.css';
import { ComponentPropsWithoutRef, createContext, useContext } from 'react';
import { PaginationButton } from './PaginationButton';
import { Pages } from './Pages';

type PaginationProps = ComponentPropsWithoutRef<'nav'> & {
  page: number;
  total: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};

type PaginationContext = PaginationProps & {
  totalPages: number;
};

const PaginationContext = createContext<PaginationContext | undefined>(
  undefined
);

export function usePaginationContext() {
  const ctx = useContext(PaginationContext);
  if (!ctx) {
    throw 'usePaginationContext must be used inside a Pagination component';
  }
  return ctx;
}

export function Pagination({
  page,
  total,
  pageSize,
  onPageChange,
  ...props
}: PaginationProps) {
  const totalPages = Math.ceil(total / pageSize);

  return (
    <PaginationContext.Provider
      value={{ page, total, pageSize, totalPages, onPageChange }}
    >
      <nav role="navigation" aria-label="Pagination" {...props}>
        <ul className={css.pagination}>
          <PaginationButton
            disabled={page === 1}
            onClick={() => onPageChange(page - 1)}
            aria-label="Previous Page"
          >
            <ChevronLeft aria-hidden />
            Previous
          </PaginationButton>

          <Pages />

          <PaginationButton
            disabled={page === totalPages}
            onClick={() => onPageChange(page + 1)}
            aria-label="Next Page"
          >
            Next
            <ChevronRight aria-hidden />
          </PaginationButton>
        </ul>
      </nav>
    </PaginationContext.Provider>
  );
}
