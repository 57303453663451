import Layout from '@components/Layout';
import { useAppQuery } from '@queries/app';
import { USER_QUERY_KEY } from '@queries/user';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export function AppPending() {
  const client = useQueryClient();
  const { teamUuid, appUuid } = useParams();
  const { data } = useAppQuery(teamUuid, appUuid, {
    refetchInterval: 2000,
  });

  useEffect(() => {
    if (!data) return;

    const existing = client.getQueryData(USER_QUERY_KEY);
    client.setQueryData(USER_QUERY_KEY, {
      ...existing,
      user: {
        ...existing?.user,
        teams: existing?.user?.teams.map((team) => {
          if (team.uuid === teamUuid) {
            return {
              ...team,
              apps: team.apps.map((app) => {
                if (app.uuid === appUuid) {
                  return {
                    ...app,
                    status: data?.status || app.status,
                  };
                }
                return app;
              }),
            };
          }
          return team;
        }),
      },
    });
  }, [data]);

  return <Layout.Loader>Initializing App...</Layout.Loader>;
}
