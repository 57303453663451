import cn from 'classnames';
import { motion } from 'motion/react';

import styles from './Tag.module.css';

export const Tag = ({
  children,
  className,
  onClick,
  textOnly = false,
  selected = false,
  variant,
  as,
  ...props
}) => {
  const Component = as ? as : motion.span;
  const isClickable = Boolean(onClick);

  return (
    <Component
      data-clickable={isClickable}
      className={cn(
        styles.tag,
        selected && styles.selected,
        textOnly && styles.textOnly,
        className
      )}
      data-variant={variant}
      onClick={onClick}
      {...props}
    >
      {children}
    </Component>
  );
};
