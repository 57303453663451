import { createElement } from 'react';
import { Button } from '@vault';

export const AppError = ({
  onRetry,
  children,
  buttonIcon,
  buttonLabel = 'Try again',
  title = 'Something went wrong.',
}) => {
  return (
    <>
      <h6 className="mb-0 color-secondary-60">Oops!</h6>
      <h3 className="mb-2">{title}</h3>
      <p className="mb-6">{children}</p>

      <Button onClick={onRetry} className="d-inline-block" prefix={buttonIcon}>
        {buttonLabel}
      </Button>
    </>
  );
};
