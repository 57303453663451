import * as RadixTabs from '@radix-ui/react-tabs';
import { motion } from 'motion/react';
import css from './styles.module.css';
import { useTabs } from './Context';

export interface TriggerProps {
  label: string;
  icon?: React.ReactNode;
}

export function Trigger({ label, icon }: TriggerProps) {
  const { value } = useTabs();

  return (
    <RadixTabs.Trigger className={css.tab} value={label}>
      {icon && (
        <span className={css.icon} aria-hidden>
          {icon}
        </span>
      )}
      {label}
      {label === value && (
        <motion.div layoutId="indicator" className={css.indicator} />
      )}
    </RadixTabs.Trigger>
  );
}
