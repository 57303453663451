import { Text } from '@vault/Text';
import { PropsWithChildren } from 'react';
import css from './styles.module.css';

export interface MenuEmptyProps extends PropsWithChildren {}

export function MenuEmpty({ children }: MenuEmptyProps) {
  return (
    <div className={css.empty}>
      <Text size="sm" color="var(--text-muted)">
        {children}
      </Text>
    </div>
  );
}
