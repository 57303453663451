import { cn } from '@vault/utilities';
import css from './styles.module.css';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';

export type BadgeProps = ComponentPropsWithoutRef<'div'> & {
  size?: 'sm' | 'md' | 'lg';
  icon?: ReactNode;
  variant?: 'primary' | 'outline' | 'red' | 'enterprise' | 'dark';
};

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
  { children, className, icon, size, variant, ...props },
  ref
) {
  const classes = cn(
    css.badge,
    className,
    size ? css[size] : css.md,
    variant ? css[variant] : css.primary
  );

  return (
    <span ref={ref} className={classes} {...props}>
      {icon}
      {children}
    </span>
  );
});
