import { Link, useParams } from 'react-router';
import Logo from '../Logo';
import css from './styles.module.css';
import UserSwitcher from './UserSwitcher';
import { UpgradeAction } from './UpgradeAction';
import { PropsWithChildren } from 'react';
import { cn } from '@vault';

interface LayoutHeaderProps extends PropsWithChildren {
  /** Whether to bleed the header into the next element */
  bleed?: boolean;
}

export default function LayoutHeader({ children, bleed }: LayoutHeaderProps) {
  const { teamUuid } = useParams();
  const logoLink = teamUuid ? `/${teamUuid}` : '/';

  return (
    <nav className={cn(css.container, !bleed && css.bordered)}>
      <div className={css.wrapper}>
        <div className={css.leftNav}>
          <Link to={logoLink}>
            <Logo className={css.logo} />
          </Link>
          {children}
        </div>
        <div className={css.rightNav}>
          <div className={css.desktopOnly}>
            <a
              className={css.docs}
              href="https://docs.evervault.com"
              target="_blank"
            >
              Docs
            </a>
            <UpgradeAction />
          </div>
          <UserSwitcher />
        </div>
      </div>
    </nav>
  );
}
