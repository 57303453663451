import { Outlet } from 'react-router';
import css from './Layout.module.css';
import LayoutErrorBoundary from './LayoutErrorBoundary';
import { Suspense } from 'react';
import LayoutLoader from './LayoutLoader';

export default function LayoutOutlet({
  children,
  fallback,
}: {
  children?: React.ReactNode;
  fallback?: React.ReactNode;
}) {
  return (
    <main className={css.main}>
      <div className={css.container}>
        <LayoutErrorBoundary>
          <Suspense fallback={fallback || <LayoutLoader />}>
            {children}
            <Outlet />
          </Suspense>
        </LayoutErrorBoundary>
      </div>
    </main>
  );
}
