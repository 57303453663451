import { Link, Text } from '@vault';
import css from './styles.module.css';
import { QueryOptions, useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useMemo } from 'react';
import { Circle } from 'lucide-react';
import { usePlain } from '@components/Plain';
import { FooterLogo } from '@components/Layout/Footer/Logo';

interface SystemStatusResponse {
  summary?: {
    subpath: string;
    ongoing_incidents: any[];
  };
}
const SYSTEM_STATUS_URL =
  'https://status.evervault.com/proxy/status.evervault.com';
const systemStatusQueryOptions: QueryOptions<SystemStatusResponse> = {
  queryKey: ['incident.io', 'status'],
  queryFn: async () => {
    const response = await fetch(SYSTEM_STATUS_URL, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    return response.json();
  },
};

function SystemStatus() {
  const systemStatus = useQuery(systemStatusQueryOptions);

  const status = useMemo(() => {
    if (systemStatus.isLoading) {
      return 'loading';
    } else if (systemStatus.error) {
      return 'error';
    }

    const response = systemStatus.data;
    if (response?.summary?.subpath !== 'evervault') {
      return 'error';
    }

    if (response.summary.ongoing_incidents.length > 0) {
      return 'incident';
    } else {
      return 'ok';
    }
  }, [systemStatus.data, systemStatus.error, systemStatus.isLoading]);

  if (status === 'loading') {
    return null;
  }

  if (status === 'error') {
    return (
      <div className={css.status}>
        <Link href="https://status.evervault.com">
          <Text size="sm" leading="md" color="var(--text-secondary)">
            Status
          </Text>
        </Link>
      </div>
    );
  }

  if (status === 'incident') {
    return (
      <div className={css.status} data-status="incident">
        <Circle aria-hidden className={css.statusIcon} />
        <Link href="https://status.evervault.com">
          <Text size="sm" leading="md" color="var(--text-secondary)">
            Ongoing incident
          </Text>
        </Link>
      </div>
    );
  }

  return (
    <div className={css.status} data-status="ok">
      <Circle aria-hidden className={css.statusIcon} />
      <Link href="https://status.evervault.com">
        <Text size="sm" leading="md" color="var(--text-secondary)">
          All systems normal
        </Text>
      </Link>
    </div>
  );
}

interface FooterLinkProps extends PropsWithChildren {
  href: string;
}
function FooterLink({ href, children }: FooterLinkProps) {
  return (
    <Link href={href}>
      <Text size="sm" leading="md" color="var(--text-secondary)">
        {children}
      </Text>
    </Link>
  );
}

export function LayoutFooter() {
  const { Plain } = usePlain();

  const copyrightYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer className={css.container}>
      <div className={css.left}>
        <FooterLogo className={css.logo} aria-label="Evervault" />

        <FooterLink href="https://evervault.com">Home</FooterLink>

        <FooterLink href="https://docs.evervault.com">Docs</FooterLink>

        <FooterLink href="https://evervault.com/legal/terms">Terms</FooterLink>

        <FooterLink href="https://evervault.com/legal/privacy">
          Privacy
        </FooterLink>

        <button className={css.support} onClick={() => Plain.show()}>
          <Text as="span" size="sm" leading="md" color="var(--text-secondary)">
            Support
          </Text>
        </button>
      </div>

      <div className={css.right}>
        <SystemStatus />

        <Text
          className={css.copyright}
          size="sm"
          leading="md"
          color="var(--text-secondary)"
        >
          &copy; {copyrightYear} Evervault Inc.
        </Text>
      </div>
    </footer>
  );
}
