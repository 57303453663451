import { cn } from '@vault/utilities';
import css from './styles.module.css';

export interface SpinnerProps {
  className?: string;
}

export function Spinner({ className }: SpinnerProps) {
  return <span className={cn(css.spinner, className)} />;
}
